/** @jsxImportSource @emotion/react */
import { css } from "@emotion/react";

import { useContext, useState, SyntheticEvent } from "react";
import { useTranslation } from "react-i18next";
import Tab from "@mui/material/Tab";

import Details from "./Details";
import TaskQueue from "./TaskQueue";
import Changes from "./Changes";
import ModelImage from "./ModelImage";
import { DetailsLoader, HeaderImageLoader } from "./loaders";

import DetailsContainer from "../components/DetailsContainer";
import { HeaderLoader } from "../loaders";
import { IndividualDetailsProps } from "../types";

import SegmentedToggle, {
    TAB_SIZES
} from "../../DesignComponents/SegmentedToggle";
import { BUTTON_SIZES } from "../../DesignComponents/Button";
import CopyButton from "../../DesignComponents/CopyButton";

import ThemeContext from "../../../context/theme/themeContext";
import TableContext from "../../../context/table/tableContext";
import InfoIcon from "../../../assets/customIcons/status/InfoIcon";
import TaskQueueIcon from "../../../assets/customIcons/tableDetails/TaskQueueIcon";
import HistoryIcon from "../../../assets/customIcons/tableDetails/HistoryIcon";
import { INVALID, useMediaQueries } from "../../../shared";

const DeviceDetails = ({ isLoading }: IndividualDetailsProps) => {
    const {
        colorsFacelift: { textDark, textPlaceholder, gray700 }
    } = useContext(ThemeContext);

    const { detailsData } = useContext(TableContext);

    const { t } = useTranslation();
    const { toMd } = useMediaQueries();

    const [tabValue, setTabValue] = useState(0);

    const { imei, model, model_platform } = detailsData;

    const handleTabChange = (_: SyntheticEvent, newValue: number) =>
        setTabValue(newValue);

    const modelName = () => {
        if (model) {
            return model === INVALID ? t(`General##${INVALID}`) : model;
        }

        return "-";
    };

    return (
        <>
            <div
                css={css({
                    display: "flex",
                    alignItems: "center",
                    marginBottom: "16px",

                    "@media(max-width: 599px)": {
                        flexDirection: "column",
                        alignItems: "flex-start"
                    }
                })}
            >
                <div
                    css={css({
                        width: "80px",
                        height: "56px",
                        borderRadius: "6px",
                        marginRight: "8px",

                        img: {
                            maxwidth: "100%",
                            maxHeight: "100%",
                            borderRadius: "6px"
                        },

                        "@media(max-width: 599px)": {
                            marginRight: "0",
                            marginBottom: "4px"
                        }
                    })}
                >
                    {isLoading ? (
                        <HeaderImageLoader />
                    ) : (
                        <ModelImage model={model} />
                    )}
                </div>

                <div>
                    {isLoading ? (
                        <HeaderLoader />
                    ) : (
                        <>
                            <div
                                css={css({
                                    fontSize: "24px",
                                    fontWeight: "600",
                                    lineHeight: "32px",
                                    textTransform: "uppercase",

                                    "span:first-of-type": {
                                        color: textPlaceholder
                                    },

                                    "span:last-of-type": {
                                        display: "inline-flex",
                                        alignItems: "center",
                                        color: textDark,

                                        "& > button": {
                                            marginLeft: "8px"
                                        }
                                    }
                                })}
                            >
                                <span>{t("Table##imei")}:</span>{" "}
                                <span>
                                    {imei}

                                    <CopyButton
                                        size={BUTTON_SIZES.Tiny}
                                        copiedValue={imei}
                                    />
                                </span>
                            </div>

                            <div
                                css={css({
                                    display: "flex",
                                    alignItems: "center",
                                    columnGap: "16px",
                                    fontSize: "14px",
                                    fontWeight: "600",
                                    lineHeight: "20px",
                                    letterSpacing: "0.1px",
                                    marginTop: "4px",

                                    "span:first-of-type": {
                                        color: textPlaceholder
                                    },

                                    "span:last-of-type": {
                                        color: gray700
                                    }
                                })}
                            >
                                <div>
                                    <span>{t("Table##model")}:</span>{" "}
                                    <span>{modelName()}</span>
                                </div>

                                <div>
                                    <span>{t("Table##platform")}:</span>{" "}
                                    <span>
                                        {model_platform
                                            ? t(`General##${model_platform}`)
                                            : "-"}
                                    </span>
                                </div>
                            </div>
                        </>
                    )}
                </div>
            </div>

            <div>
                <SegmentedToggle
                    value={tabValue}
                    size={TAB_SIZES.Small}
                    tabWidth={toMd ? "258px" : "250px"}
                    onChange={handleTabChange}
                >
                    <Tab
                        label={t("Table##device details")}
                        icon={<InfoIcon />}
                    />

                    <Tab label={t("Table##tasks")} icon={<TaskQueueIcon />} />

                    <Tab label={t("Table##changes")} icon={<HistoryIcon />} />
                </SegmentedToggle>
            </div>

            {tabValue === 0 && (
                <DetailsContainer>
                    {isLoading ? <DetailsLoader /> : <Details />}
                </DetailsContainer>
            )}

            {tabValue === 1 && <TaskQueue />}
            {tabValue === 2 && <Changes />}
        </>
    );
};

export default DeviceDetails;
