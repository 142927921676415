/** @jsxImportSource @emotion/react */
import { css } from "@emotion/react";

import { useContext, useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";

import Company from "./Company";

import { CompanyComponentProps } from "../TableDetails/CompanyDetails/types/interfaces";

import TableContext from "../../context/table/tableContext";
import {
    API_IS_BEING_CANCELLED,
    ENDPOINTS,
    HierarchyLoader,
    HierarchyTitleLoader,
    useApi,
    useIsMounted
} from "../../shared";

export const CompanyHierarchy = ({
    isReccuringLoading,
    titleCSS
}: CompanyComponentProps) => {
    const { idForDetailsData, reloadDetailsData } = useContext(TableContext);

    const { t } = useTranslation();

    const { cancelSource, isCanceled, getData, handleResponse } = useApi();
    const isMounted = useIsMounted();
    const isInitialLoad = useRef(true);

    const [isLoading, setLoading] = useState(false);
    const [companies, setCompanies] = useState([]);

    useEffect(() => {
        const getInheritanceData = async () => {
            try {
                !isReccuringLoading && setLoading(true);

                const { data } = await getData(
                    `${ENDPOINTS.Companies}/${idForDetailsData}/inheritance`
                );

                if (isMounted) {
                    setCompanies(data);
                    !isReccuringLoading && setLoading(false);
                }
            } catch (error) {
                if (isMounted && !isCanceled(error)) {
                    handleResponse(error);
                    setCompanies([]);
                    !isReccuringLoading && setLoading(false);
                }
            }

            if (isInitialLoad.current) {
                isInitialLoad.current = false;
            }
        };

        getInheritanceData();

        return () => {
            cancelSource.cancel(API_IS_BEING_CANCELLED);
        };

        // eslint-disable-next-line
    }, [idForDetailsData, reloadDetailsData]);

    return (
        <>
            <div>
                {isInitialLoad.current ? (
                    <HierarchyTitleLoader />
                ) : (
                    <div
                        css={css({
                            marginBottom: "8px",
                            opacity: isLoading ? "0.4" : "1",
                            ...titleCSS
                        })}
                    >
                        {t("Table##company hierarchy")}
                    </div>
                )}

                {isInitialLoad.current ? (
                    <HierarchyLoader />
                ) : (
                    <ul
                        css={css({
                            opacity: isLoading ? "0.4" : "1",
                            display: "flex",
                            flexDirection: "column",
                            rowGap: "4px",
                            paddingBottom: "8px",
                            overflowX: "auto"
                        })}
                    >
                        {companies.map((company: any, i: number) => {
                            return (
                                <Company
                                    key={company.id}
                                    data={company}
                                    index={i}
                                    isActive={i === companies.length - 1}
                                />
                            );
                        })}
                    </ul>
                )}
            </div>
        </>
    );
};

export default CompanyHierarchy;
