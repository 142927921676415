import { useContext, useRef } from "react";

import TableContext from "../../context/table/tableContext";

const useRecurringLoading = () => {
    const { reloadDetailsData } = useContext(TableContext);

    const prevReloadDetailsData = useRef(reloadDetailsData);

    const isReccuringLoading =
        prevReloadDetailsData.current !== reloadDetailsData;

    prevReloadDetailsData.current = reloadDetailsData;

    return { isReccuringLoading };
};

export { useRecurringLoading };
