import { SyntheticEvent, useContext, useEffect, useState } from "react";

import Header from "./Header";
import TabSection from "./TabSection";
import Details from "./Details";
import OfflineLicenses from "./OfflineLicenses";
import { TABS } from "./types";

import DetailsContainer from "../components/DetailsContainer";
import { DetailsLoader } from "../DeviceDetails/loaders";
import { HeaderLoader } from "../loaders";
import { IndividualDetailsProps } from "../types";

import TableContext from "../../../context/table/tableContext";

const LicenseDetails = ({ isLoading }: IndividualDetailsProps) => {
    const {
        detailsData: { enabled }
    } = useContext(TableContext);

    const [tabValue, setTabValue] = useState(TABS.LicenseDetails);

    useEffect(() => {
        !enabled && setTabValue(TABS.LicenseDetails);
    }, [enabled]);

    const handleTabChange = (_: SyntheticEvent, newValue: TABS) =>
        setTabValue(newValue);

    const isDetailsTabSelected = tabValue === TABS.LicenseDetails;

    return (
        <>
            {isLoading ? (
                <HeaderLoader
                    customStyle={{
                        marginBottom: "16px",

                        "@media(max-width: 839px)": {
                            marginBottom: "24px"
                        }
                    }}
                />
            ) : (
                <Header isDetailsTabSelected={isDetailsTabSelected} />
            )}

            <TabSection
                tabValue={tabValue}
                isEnabled={enabled}
                handleTabChange={handleTabChange}
            />

            {isDetailsTabSelected ? (
                <DetailsContainer>
                    {isLoading ? <DetailsLoader /> : <Details />}
                </DetailsContainer>
            ) : (
                <OfflineLicenses isLoading={isLoading} />
            )}
        </>
    );
};

export default LicenseDetails;
