import General from "./General";
import OtherInfo from "./OtherInfo";

const Details = () => {
    return (
        <>
            <General />
            <OtherInfo />
        </>
    );
};

export default Details;
