import { useTranslation } from "react-i18next";
import { Moment } from "moment";
import {
    DateValidationError,
    PickerChangeHandlerContext
} from "@mui/x-date-pickers";

import { FirmwareDateProps } from "./types";

import DatePicker, { useDatePicker } from "../../../../../../../DatePicker";

import { INVALID_DATE } from "../../../../../../../../shared";

const FirmwareDate = ({ isLoading, value, changeValue }: FirmwareDateProps) => {
    const { t } = useTranslation();
    const { parseDatePickerValue } = useDatePicker();

    const handleChange = (
        value: Moment | null,
        context: PickerChangeHandlerContext<DateValidationError>
    ) => {
        const newValue = parseDatePickerValue(value, context, INVALID_DATE);

        changeValue({ rawDate: value, stringifiedDate: newValue });
    };

    return (
        <DatePicker
            label={t("Dialog##firmware date")}
            value={value}
            onChange={handleChange}
            readOnly={isLoading}
            customStyle={{
                marginTop: "16px"
            }}
        />
    );
};

export default FirmwareDate;
