import { useContext, useEffect, useState } from "react";

import DesktopDetails from "./DesktopDetails";
import MobileDetails from "./MobileDetails";
import DeviceDetails from "./DeviceDetails";
import TaskGroupDetails from "./TaskGroupDetails";
import CompanyDetails from "./CompanyDetails";
import LicenseDetails from "./LicenseDetails";
import { TableDetailsProps } from "./types";

import TableContext from "../../context/table/tableContext";
import { ENDPOINTS, TABLE_NAMES, useApi, useMediaQueries } from "../../shared";

const TableDetails = ({ heights, setDefaultHeigth }: TableDetailsProps) => {
    const { idForDetailsData, tableName, setDetailsData, updateSingleRowData } =
        useContext(TableContext);

    const { getData, handleResponse } = useApi();
    const { toMd } = useMediaQueries();

    const [isLoading, setLoading] = useState(false);

    useEffect(() => {
        const getSelectedRowData = async (hasLoader: boolean) => {
            try {
                hasLoader && setLoading(true);

                const resource =
                    tableName === TABLE_NAMES.DesktopLicenses
                        ? ENDPOINTS.DesktopLicenses
                        : tableName;

                const { data } = await getData(
                    `${resource}/${idForDetailsData}`
                );

                updateSingleRowData(data, !hasLoader);
            } catch (error) {
                setDetailsData(null);
                handleResponse(error);
            }

            hasLoader && setLoading(false);
        };

        idForDetailsData && getSelectedRowData(true);

        const timer = setInterval(
            () => idForDetailsData !== null && getSelectedRowData(false),
            60000
        );

        return () => clearInterval(timer);
        // eslint-disable-next-line
    }, [idForDetailsData]);

    const renderContent = () => {
        switch (tableName) {
            case TABLE_NAMES.Devices:
                return <DeviceDetails isLoading={isLoading} />;
            case TABLE_NAMES.TaskGroups:
                return <TaskGroupDetails isLoading={isLoading} />;
            case TABLE_NAMES.Companies:
                return <CompanyDetails isLoading={isLoading} />;
            case TABLE_NAMES.DesktopLicenses:
                return <LicenseDetails isLoading={isLoading} />;
            default:
                return <div></div>;
        }
    };

    return toMd ? (
        <MobileDetails isLoading={isLoading}>{renderContent()}</MobileDetails>
    ) : (
        <DesktopDetails
            isLoading={isLoading}
            heights={heights}
            setDefaultHeigth={setDefaultHeigth}
        >
            {renderContent()}
        </DesktopDetails>
    );
};

export default TableDetails;
