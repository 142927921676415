import { useEffect, useState } from "react";

import AdapterTypes from "./AdapterTypes";
import FirmwareDate from "./FirmwareDate";
import Revision from "./Revision";
import { useRevisionValidation } from "./hooks";
import { CanAdaptersProps, PayloadValue } from "./types";

import { PAYLOAD_ID } from "../../types";

import { INVALID_DATE, TASK_TYPES } from "../../../../../../../../shared";

const CanAdapters = ({
    isLoading,
    type,
    additionalData,
    changePayload,
    changeDateValue,
    changeRevisionValue,
    changeAdapterValue
}: CanAdaptersProps) => {
    const { isValidRevision } = useRevisionValidation();

    const {
        adapter,
        revisionValue,
        dateValue: { rawDate, stringifiedDate }
    } = additionalData;

    const [payloadValue, setPayloadValue] = useState<PayloadValue>({
        protocol_id: null,
        firmware_date: null,
        firmware_version: null
    });

    const { protocol_id, firmware_date, firmware_version } = payloadValue;

    useEffect(() => {
        setPayloadValue({
            protocol_id: adapter?.protocol_id || null,
            firmware_date: stringifiedDate,
            firmware_version: revisionValue || null
        });
    }, [adapter, stringifiedDate, revisionValue]);

    useEffect(() => {
        const isCorrectFwDate = firmware_date !== INVALID_DATE;
        const isCorrectFwVersion = isValidRevision(firmware_version, true);

        if (
            isCorrectFwDate &&
            isCorrectFwVersion &&
            (type === TASK_TYPES.TxCanAdapterUpdate
                ? true
                : protocol_id !== null)
        ) {
            const canAdaptersPayload = {
                firmware_date,
                firmware_version,
                ...(type !== TASK_TYPES.TxCanAdapterUpdate && {
                    protocol_id
                })
            };

            changePayload({
                id: PAYLOAD_ID.Attributes,
                value: JSON.stringify(canAdaptersPayload)
            });
        } else {
            changePayload(null);
        }

        // eslint-disable-next-line
    }, [protocol_id, firmware_date, firmware_version, type]);

    return (
        <>
            {type !== TASK_TYPES.TxCanAdapterUpdate && (
                <AdapterTypes
                    taskType={type}
                    isSubmitLoading={isLoading}
                    value={adapter}
                    changeType={changeAdapterValue}
                />
            )}

            <FirmwareDate
                key={type}
                isLoading={isLoading}
                value={rawDate}
                changeValue={changeDateValue}
            />

            {revisionValue !== null && (
                <Revision
                    value={revisionValue}
                    changeValue={changeRevisionValue}
                />
            )}
        </>
    );
};

export default CanAdapters;
