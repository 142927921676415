import { useContext, useEffect } from "react";

import ParamsContext from "../../../context/params/paramsContext";
import TableContext from "../../../context/table/tableContext";
import {
    API_IS_BEING_CANCELLED,
    useApi,
    useIsMounted,
    useParamsFromUrlAndBrowser
} from "../../../shared";

export const useTableData = () => {
    const { queryParams, stringParams, reloadItems } =
        useContext(ParamsContext);

    const {
        customData,
        isInnerTable,
        tableName,
        customResource,
        sortFromNewest,
        innerTableStringParams,
        isTableLoading,
        outerDetailsData,
        setTableData,
        setTableLoading,
        getInnerTableData
    } = useContext(TableContext);

    const isMounted = useIsMounted();
    const { cancelSource, isCanceled, queryData, handleResponse } = useApi();
    const { stringifiedCurrentParams } = useParamsFromUrlAndBrowser();

    const reloadItem = reloadItems[tableName];
    const reloadDetailsData = outerDetailsData?.reloadDetailsData;
    const isReccuringLoading = outerDetailsData?.isReccuringLoading;
    const isFakeLoading = outerDetailsData?.isFakeLoading;

    const getDataOfAllRows = async (hasLoader: boolean) => {
        if (hasLoader && !isTableLoading) {
            setTableLoading(true);
            getInnerTableData?.({ isInnerTableLoading: true });
        }

        try {
            const parameters = isInnerTable
                ? innerTableStringParams
                : stringParams;

            const hasSort =
                !isInnerTable &&
                !Object.keys(queryParams).includes("sort") &&
                sortFromNewest;

            const response = await queryData(
                customResource || tableName,
                parameters,
                hasSort
            );

            if (isMounted) {
                const {
                    data: { current_page, data, per_page, total }
                } = response;

                const payload = {
                    rows: data,
                    currentPage: current_page,
                    rowsPerPage: per_page,
                    totalRows: total
                };

                setTableData(payload);

                getInnerTableData?.({
                    isInnerTableLoading: false,
                    ...payload
                });
            }
        } catch (error) {
            if (isMounted && !isCanceled(error)) {
                handleResponse(error);

                const payload = {
                    rows: [],
                    currentPage: 1,
                    rowsPerPage: 0,
                    totalRows: 0
                };

                setTableData(payload);

                getInnerTableData?.({
                    isInnerTableLoading: false,
                    ...payload
                });
            }
        }
    };

    // To avoid duplicate requests when going through pages when filters are applied
    const areSameParams = stringParams === stringifiedCurrentParams;

    // Get main table data
    useEffect(() => {
        !customData && !isInnerTable && areSameParams && getDataOfAllRows(true);

        return () => {
            cancelSource.cancel(API_IS_BEING_CANCELLED);
        };

        // eslint-disable-next-line
    }, [stringParams, areSameParams, reloadItem]);

    // Get inner table data
    useEffect(() => {
        !customData && isInnerTable && getDataOfAllRows(!isReccuringLoading);

        return () => {
            cancelSource.cancel(API_IS_BEING_CANCELLED);
        };

        // eslint-disable-next-line
    }, [innerTableStringParams, customResource, reloadItem, reloadDetailsData]);

    // Set fake loading from the outside component
    useEffect(() => {
        isFakeLoading !== undefined && setTableLoading(isFakeLoading);
    }, [isFakeLoading, setTableLoading]);
};
