import { useContext } from "react";

import {
    Accessories,
    Can,
    CanAdapter,
    Carrier,
    ConfigurationDetailsAndSim,
    General,
    Obd
} from "./Cards";
import { VIN_PARSING_LIMIT } from "./constants";
import { DetailsData } from "./types";

import AuthContext from "../../../../context/auth/authContext";
import TableContext from "../../../../context/table/tableContext";

const Details = () => {
    const { isAdminUser, isCarrierUser } = useContext(AuthContext);
    const { detailsData } = useContext(TableContext);

    const {
        obd,
        can_adapter,
        accessories,
        carrier,
        can,
        can_package
    }: DetailsData = detailsData;

    const renderObd =
        !!obd?.vin ||
        !!obd?.chip_version ||
        obd?.period_requests >= VIN_PARSING_LIMIT;

    const renderCan =
        !!can_package ||
        !!can?.can_package ||
        !!can?.chip_version ||
        !!can?.vehicle ||
        !!can?.vehicle_id ||
        !!can?.can_package_id;

    const renderCarrier = (isAdminUser || isCarrierUser) && carrier;

    return (
        <>
            <General />
            <ConfigurationDetailsAndSim />

            {renderObd && <Obd />}
            {can_adapter && <CanAdapter />}
            {renderCan && <Can />}
            {accessories && <Accessories />}
            {renderCarrier && <Carrier />}
        </>
    );
};

export default Details;
