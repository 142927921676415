/** @jsxImportSource @emotion/react */
import { css } from "@emotion/react";

import { useContext } from "react";
import { useTranslation } from "react-i18next";

import CompanyTooltip from "./CompanyTooltip";

import { HeaderLoader } from "../loaders";
import { IndividualDetailsProps } from "../types";

import ThemeContext from "../../../context/theme/themeContext";
import TableContext from "../../../context/table/tableContext";

export const CompanyHeader = ({ isLoading }: IndividualDetailsProps) => {
    const {
        colorsFacelift: { textDark, textPlaceholder }
    } = useContext(ThemeContext);

    const { detailsData } = useContext(TableContext);

    const { t } = useTranslation();

    return (
        <div
            css={css({
                marginRight: "60px",
                marginBottom: "24px"
            })}
        >
            {isLoading ? (
                <HeaderLoader />
            ) : (
                <>
                    <div
                        css={css({
                            color: textDark,
                            fontWeight: "600",
                            marginBottom: "4px"
                        })}
                    >
                        <CompanyTooltip
                            name={detailsData?.name}
                            lineH={32}
                            fontSize={24}
                        />
                    </div>

                    <div
                        css={css({
                            display: "flex",
                            alignItems: "center",
                            fontSize: "14px",
                            lineHeight: "20px",
                            letterSpacing: "0.1px",
                            fontWeight: "600",
                            marginTop: "4px",

                            "span:first-of-type": {
                                color: textPlaceholder,
                                marginRight: "5px"
                            },

                            "span:last-of-type": {
                                color: textDark,
                                flex: "1",
                                whiteSpace: "nowrap",
                                overflowX: "hidden",
                                textOverflow: "ellipsis"
                            }
                        })}
                    >
                        <span>{t(`Table##parent company`)}: </span>

                        <span>
                            {detailsData?.company
                                ? detailsData?.company.name
                                : t("Table##no parent company")}
                        </span>
                    </div>
                </>
            )}
        </div>
    );
};

export default CompanyHeader;
