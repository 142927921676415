import {
    SET_TABLE_DATA,
    SET_INNER_TABLE_PARAMS,
    RESET_INNER_TABLE_ALL_PARAMS,
    RESET_INNER_TABLE_FILTER_PARAMS,
    TOGGLE_ACTIVE_COLUMN,
    RESTORE_ACTIVE_COLUMNS,
    SET_TABLE_LOADING,
    TOGGLE_SELECTION_OF_SINGLE_ROW,
    SELECT_MULTIPLE_ROWS,
    DESELECT_MULTIPLE_ROWS,
    DESELECT_ALL_AND_SELECT_MULTIPLE,
    DESELECT_ALL_ROWS,
    SET_DETAILS_DATA,
    UPDATE_SINGLE_ROW_DATA,
    DELETE_MULTIPLE_ROWS,
    SET_CUSTOM_RESOURCE,
    UPDATE_OUTER_DETAILS_DATA,
    SET_ID_FOR_DETAILS_DATA
} from "./tableActions";
import {
    deleteUpdatedQueryParams,
    getActiveQueryParams,
    updateSelectedRows,
    getTotalRows,
    getSelectableRows,
    getUpdatedSelectedRows,
    getUpdatedRowsAfterSingleRowUpdate,
    getUpdatedRowsAfterMultipleRowsDelete,
    updateIdForDetails,
    updateDetailsData,
    updateReloadDetailsData,
    updateDetailsDataFromRowData
} from "./functions";
import { Action, TableInitialState } from "./types";

import { getFilteredValues } from "../params/functions";

import {
    Column,
    QueryParamName,
    isFilter,
    getStringifiedParams
} from "../../shared";

const TableReducer = (
    state: TableInitialState,
    action: Action
): TableInitialState => {
    switch (action.type) {
        case SET_TABLE_DATA: {
            const { outerDetailsData, rows, user, ...rest } = action.payload;

            return {
                ...state,
                ...rest,
                rows,
                selectableRows: getSelectableRows(state.tableName, rows, user),
                isInitialLoading: false,
                isTableLoading: false,
                outerDetailsData: outerDetailsData || null
            };
        }
        case SET_INNER_TABLE_PARAMS: {
            const { updatedParams: queryParams, filteredValues } =
                action.payload;

            const isEmpty = Object.keys(queryParams).length === 0;

            const activeQueryParams = getActiveQueryParams(
                isEmpty,
                state,
                queryParams
            );

            const stringifiedParams = getStringifiedParams(activeQueryParams);

            const updatedFilteredValues = getFilteredValues(
                [...state.innerTableFilteredValues],
                filteredValues
            );

            return {
                ...state,
                innerTableQueryParams: activeQueryParams,
                innerTableStringParams: stringifiedParams,
                innerTableFilteredValues: updatedFilteredValues
            };
        }
        case SET_CUSTOM_RESOURCE: {
            return {
                ...state,
                customResource: action.payload
            };
        }
        case RESET_INNER_TABLE_ALL_PARAMS: {
            const updatedQueryParams = state.innerTableQueryParams;
            const initialParams = state.innerTableInitialParamNames;

            const paramNames = Object.keys(
                updatedQueryParams
            ) as QueryParamName[];

            paramNames.forEach(key => {
                deleteUpdatedQueryParams(
                    initialParams,
                    key,
                    isFilter,
                    updatedQueryParams
                );
            });

            const stringifiedParams = getStringifiedParams(updatedQueryParams);

            return {
                ...state,
                innerTableQueryParams: updatedQueryParams,
                innerTableStringParams: stringifiedParams,
                innerTableFilteredValues: []
            };
        }
        case RESET_INNER_TABLE_FILTER_PARAMS: {
            const updatedQueryParams = { ...state.innerTableQueryParams };
            const initialParams = state.innerTableInitialParamNames;

            const paramNames = Object.keys(
                updatedQueryParams
            ) as QueryParamName[];

            paramNames.forEach(key => {
                if (!initialParams.includes(key) && isFilter(key)) {
                    delete updatedQueryParams[key];
                }
            });

            const stringifiedParams = getStringifiedParams(updatedQueryParams);

            return {
                ...state,
                innerTableQueryParams: updatedQueryParams,
                innerTableStringParams: stringifiedParams,
                innerTableFilteredValues: []
            };
        }
        case TOGGLE_ACTIVE_COLUMN: {
            const { tableName, columns } = state;

            const currentColumns = [...columns];
            const columnName = action.payload;

            const updatedColumns = currentColumns.map((column: Column) =>
                column.name === columnName
                    ? { ...column, isActive: !Boolean(column.isActive) }
                    : column
            );

            const setToLocalStorage = () => {
                type StorageColumns = {
                    [key: string]: boolean;
                };
                const storageColumns: StorageColumns = {};

                updatedColumns.forEach(
                    column => (storageColumns[column.name] = column.isActive)
                );

                localStorage.setItem(
                    `${tableName}ColumnsV2`,
                    JSON.stringify(storageColumns)
                );
            };

            setToLocalStorage();

            return {
                ...state,
                columns: updatedColumns
            };
        }
        case RESTORE_ACTIVE_COLUMNS: {
            const { tableName, columns } = state;

            const currentColumns = [...columns];

            const updatedColumns = currentColumns.map(column => ({
                ...column,
                isActive: Boolean(column.isDefaultActive)
            }));

            localStorage.removeItem(`${tableName}ColumnsV2`);

            return {
                ...state,
                columns: updatedColumns
            };
        }
        case SET_TABLE_LOADING: {
            return {
                ...state,
                isTableLoading: action.payload
            };
        }
        case TOGGLE_SELECTION_OF_SINGLE_ROW: {
            const rowId = action.payload;

            const updatedSelectedRows = updateSelectedRows(state, rowId);

            return {
                ...state,
                selectedRows: updatedSelectedRows
            };
        }
        case SELECT_MULTIPLE_ROWS: {
            return {
                ...state,
                selectedRows: [...state.selectedRows, ...action.payload]
            };
        }
        case DESELECT_ALL_AND_SELECT_MULTIPLE: {
            return {
                ...state,
                selectedRows: action.payload
            };
        }
        case DESELECT_MULTIPLE_ROWS: {
            const updatedRows = state.selectedRows.filter(
                row => !action.payload.includes(row)
            );

            return {
                ...state,
                selectedRows: updatedRows
            };
        }
        case DESELECT_ALL_ROWS: {
            return {
                ...state,
                selectedRows: []
            };
        }
        case SET_ID_FOR_DETAILS_DATA: {
            const id = action.payload;

            return {
                ...state,
                idForDetailsData: id,
                detailsData: updateDetailsData(id, state.detailsData)
            };
        }
        case SET_DETAILS_DATA: {
            const detailsData = action.payload;

            return {
                ...state,
                idForDetailsData: updateIdForDetails(
                    detailsData,
                    state.idForDetailsData
                ),
                detailsData
            };
        }
        case UPDATE_SINGLE_ROW_DATA: {
            const {
                tableName,
                idType,
                rows,
                selectedRows,
                idForDetailsData,
                detailsData,
                reloadDetailsData
            } = state;

            const { data: newData, user, shouldReloadDetails } = action.payload;
            const rowId = newData[idType];

            const updatedRows = getUpdatedRowsAfterSingleRowUpdate(
                rows,
                idType,
                rowId,
                newData
            );

            const updatedSelectableRows = getSelectableRows(
                tableName,
                updatedRows,
                user
            );

            const updatedSelectedRows = getUpdatedSelectedRows(
                selectedRows,
                updatedSelectableRows,
                idType
            );

            return {
                ...state,
                rows: updatedRows,
                selectableRows: updatedSelectableRows,
                selectedRows: updatedSelectedRows,
                detailsData: updateDetailsDataFromRowData(
                    idForDetailsData,
                    rowId,
                    newData,
                    detailsData
                ),
                reloadDetailsData: updateReloadDetailsData(
                    shouldReloadDetails,
                    reloadDetailsData
                )
            };
        }
        case UPDATE_OUTER_DETAILS_DATA: {
            return { ...state, outerDetailsData: action.payload };
        }
        case DELETE_MULTIPLE_ROWS: {
            const { idType, tableName, rows } = state;
            const { id: idArray, user } = action.payload;

            const updatedRows = getUpdatedRowsAfterMultipleRowsDelete(
                rows,
                idArray,
                idType
            );

            return {
                ...state,
                rows: updatedRows,
                selectableRows: getSelectableRows(tableName, updatedRows, user),
                totalRows: getTotalRows(state.totalRows, idArray.length)
            };
        }
        default:
            return state;
    }
};

export default TableReducer;
