import { useEffect, useState } from "react";

import {
    AdapterValue,
    DateValue
} from "../SecondSelectContent/CanAdapters/types";
import { SelectedTask } from "../types";

import { INVALID_DATE } from "../../../../../../../shared";

const useCanAdapters = (selectedTask: SelectedTask) => {
    const [dateValue, setDateValue] = useState<DateValue>({
        rawDate: null,
        stringifiedDate: null
    });

    const [revisionValue, setRevisionValue] = useState<string | null>(null);

    const [adapter, setAdapter] = useState<AdapterValue>(null);

    useEffect(() => {
        setDateValue({
            rawDate: null,
            stringifiedDate: null
        });

        setRevisionValue(null);

        setAdapter(null);
    }, [selectedTask?.type]);

    const changeDateValue = (newValue: DateValue) => {
        setDateValue(newValue);

        if (newValue.stringifiedDate === INVALID_DATE) {
            setRevisionValue(null);
        } else if (revisionValue === null) {
            setRevisionValue("");
        }
    };

    const changeRevisionValue = (newValue: string) =>
        setRevisionValue(newValue);

    const changeAdapterValue = (adapterInfo: AdapterValue) =>
        setAdapter(adapterInfo);

    return {
        additionalData: { dateValue, revisionValue, adapter },
        changeDateValue,
        changeRevisionValue,
        changeAdapterValue
    };
};

export { useCanAdapters };
