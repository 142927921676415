/** @jsxImportSource @emotion/react */
import { css } from "@emotion/react";

import SearchRoundedIcon from "@mui/icons-material/SearchRounded";
import { SyntheticEvent, useContext, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import Tab from "@mui/material/Tab";

import FoundResourceDetails from "./FoundResourceDetails";

import { FIND_TOOL_TABS } from "../constants";
import { FIND_TOOL_TAB_VALUES, FindToolContentProps } from "../types";

import ControlsItemFrame from "../../../Navbar/Controls/components/ControlsItemFrame";
import InputField, {
    ClearInputIcon,
    INPUT_FIELD_SIZES
} from "../../../DesignComponents/InputField";
import SegmentedToggle, {
    TAB_SIZES
} from "../../../DesignComponents/SegmentedToggle";

import ThemeContext from "../../../../context/theme/themeContext";
import {
    ENDPOINTS,
    isValidNumberValue,
    useApi,
    useDebounce,
    useEmailValidation,
    useMediaQueries
} from "../../../../shared";

const FindToolContent = ({ close }: FindToolContentProps) => {
    const {
        colorsFacelift: { textDark, gray700 }
    } = useContext(ThemeContext);

    const { getData } = useApi();
    const { t } = useTranslation();
    const { toMd } = useMediaQueries();

    const [searchValue, setSearchValue] = useState("");
    const [error, setError] = useState<string | null>(null);
    const [notFoundErrorMsg, setNotFoundErrorMsg] = useState("");
    const [resourceData, setResourceData] = useState(null);
    const [tabValue, setTabValue] = useState(FIND_TOOL_TAB_VALUES.Device);

    const debouncedValue = useDebounce(searchValue, 1000);
    const { isEmailValid } = useEmailValidation(searchValue);

    const isDeviceTab = tabValue === FIND_TOOL_TAB_VALUES.Device;

    useEffect(() => {
        if (
            (isDeviceTab && debouncedValue.length === 15) ||
            (!isDeviceTab && isEmailValid)
        ) {
            getResourceInfo(debouncedValue);
        } else {
            debouncedValue.length === 0
                ? setError(null)
                : setError(
                      t(
                          `Dialog##find tool##${FIND_TOOL_TABS[tabValue].label}##error not valid`
                      )
                  );

            handleClear(false);
        }

        // eslint-disable-next-line
    }, [debouncedValue]);

    const handlePaste = (e: any) => {
        e.preventDefault();

        const value = e.clipboardData.getData("text").trim();

        handleSearchValue(value);
    };

    const handleSearchValue = (value: string) => {
        const isValidDeviceValue = isValidNumberValue(value);

        if (isDeviceTab && !isValidDeviceValue) {
            setError(t("Dialog##only numbers"));
            setNotFoundErrorMsg("");
        } else {
            setError(null);
            setSearchValue(value);
        }
    };

    const handleClear = (clearSearchValue: boolean) => {
        clearSearchValue && setError(null);
        setNotFoundErrorMsg("");
        clearSearchValue && setSearchValue("");
        setResourceData(null);
    };

    const handleTabChange = (_: SyntheticEvent, value: number) => {
        handleClear(true);
        setTabValue(value);
    };

    const getResourceInfo = async (resourceValue: string) => {
        const resourceEndpoint =
            tabValue === FIND_TOOL_TAB_VALUES.Device
                ? ENDPOINTS.Devices
                : ENDPOINTS.Users;

        try {
            const { data } = await getData(
                `${resourceEndpoint}/global/${resourceValue}`
            );

            setResourceData(data);
            setNotFoundErrorMsg("");
        } catch (error) {
            setResourceData(null);
            setNotFoundErrorMsg((error as any)?.response?.data?.message || "");
        }
    };

    const renderContent = () => {
        if (resourceData) {
            return (
                <FoundResourceDetails
                    resourceData={resourceData}
                    tabData={FIND_TOOL_TABS[tabValue]}
                    close={close}
                />
            );
        }

        if (notFoundErrorMsg) {
            return (
                <div
                    css={css({
                        display: "flex",
                        flex: "1",
                        flexDirection: "column",
                        justifyContent: "center",
                        lineHeight: "20px",
                        padding: "28px 32px 12px 32px",
                        textAlign: "center",

                        "& > span:nth-of-type(1)": {
                            color: textDark,
                            fontSize: "16px",
                            fontWeight: "600",
                            letterSpacing: "0.15px",
                            marginBottom: "8px"
                        },

                        "& > span:nth-of-type(2)": {
                            color: gray700,
                            fontSize: "14px",
                            fontWeight: "400",
                            letterSpacing: "0.1px"
                        }
                    })}
                >
                    <span>
                        {t(
                            `Dialog##find tool##${FIND_TOOL_TABS[tabValue].label}##error title`
                        )}
                    </span>

                    <span>
                        {t(
                            `Dialog##find tool##${FIND_TOOL_TABS[tabValue].label}##error subtitle`
                        )}
                    </span>
                </div>
            );
        }

        return null;
    };

    return (
        <>
            <ControlsItemFrame
                close={close}
                boxPadding={toMd ? "24px 24px 0px 24px" : "16px 16px 0px 16px"}
                itemName={t("Button##find tool")}
            />

            <div
                css={css({
                    padding: "12px 16px 16px 16px"
                })}
            >
                <SegmentedToggle
                    value={tabValue}
                    size={TAB_SIZES.Small}
                    fullWidth
                    onChange={handleTabChange}
                    css={css({
                        marginBottom: "12px"
                    })}
                >
                    {FIND_TOOL_TABS.map(tab => {
                        const { label, icon: Icon } = tab;

                        return (
                            <Tab
                                key={`${label}-find-tool-tab`}
                                label={t(`Button##${label}`)}
                                icon={
                                    <Icon
                                        css={css({
                                            fontSize: "16px"
                                        })}
                                    />
                                }
                            />
                        );
                    })}
                </SegmentedToggle>

                <InputField
                    autoFocus
                    fullWidth
                    size={INPUT_FIELD_SIZES.Small}
                    placeholder={t(
                        `Search##search ${FIND_TOOL_TABS[tabValue].label} ${FIND_TOOL_TABS[tabValue].searchResource}`
                    )}
                    iconLeft={<SearchRoundedIcon />}
                    value={searchValue}
                    onPaste={handlePaste}
                    onChange={e => handleSearchValue(e.target.value)}
                    iconRight={
                        <ClearInputIcon
                            value={searchValue}
                            clearValue={() => handleClear(true)}
                        />
                    }
                    errorText={error}
                />

                {renderContent()}
            </div>
        </>
    );
};

export default FindToolContent;
