import { useContext, useState, useEffect } from "react";

import { HeaderTitleLoader } from "./loaders";
import { MobileDetailsProps } from "./types";

import DialogCustom from "../DesignComponents/DialogCustom";

import TableContext from "../../context/table/tableContext";

const MobileDetails = ({ children, isLoading }: MobileDetailsProps) => {
    const { detailsData, idForDetailsData, setDetailsData } =
        useContext(TableContext);

    const [isOpen, setOpen] = useState(Boolean(detailsData));

    useEffect(() => {
        setOpen(idForDetailsData !== null);
    }, [idForDetailsData]);

    const renderContent = () => {
        if (idForDetailsData && detailsData) {
            return children;
        }

        return isLoading ? <HeaderTitleLoader /> : <div></div>;
    };

    return (
        <DialogCustom
            fullWidth
            open={isOpen}
            close={() => setDetailsData(null)}
            transitionDuration={0}
            customPadding="16px 24px"
        >
            {renderContent()}
        </DialogCustom>
    );
};

export default MobileDetails;
