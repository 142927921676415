/** @jsxImportSource @emotion/react */
import { css } from "@emotion/react";

import { useState, useRef, useContext, useEffect, Fragment } from "react";
import { useTranslation } from "react-i18next";
import DragHandleIcon from "@mui/icons-material/DragHandleRounded";
import ExpandMoreRoundedIcon from "@mui/icons-material/ExpandMoreRounded";

import { HeaderTitleLoader } from "./loaders";
import { DesktopDetailsProps } from "./types";

import { MIN_HEIGHT } from "../Table/constants";
import {
    TOOLTIP_PLACEMENT,
    TooltipGeneral
} from "../DesignComponents/Tooltips";
import Button, {
    BUTTON_COLORS,
    BUTTON_SIZES,
    BUTTON_VARIANTS
} from "../DesignComponents/Button";

import { COLORS, ThemeContext } from "../../context/theme";
import TableContext from "../../context/table/tableContext";
import ParamsContext from "../../context/params/paramsContext";
import RoutesContext from "../../context/routes/routesContext";
import { FILTER_NAMES } from "../../shared";

const DesktopDetails = ({
    children,
    isLoading,
    heights,
    setDefaultHeigth
}: DesktopDetailsProps) => {
    const {
        colorsFacelift: { blue500, gray100, gray200, textDark, white }
    } = useContext(ThemeContext);

    const { queryParams, reloadItems } = useContext(ParamsContext);
    const { isDesktopDrawerOpen } = useContext(RoutesContext);

    const { detailsData, idForDetailsData, tableName, setDetailsData } =
        useContext(TableContext);

    const { t } = useTranslation();

    const { initialDefaultHeight, defaultHeight, tableHeight } = heights;

    const [isDragging, setIsDragging] = useState(false);
    const [height, setHeight] = useState(86);

    const firstRender = useRef(true);
    const initialPosition = useRef<number>(0);
    const initialHeight = useRef<number>(0);
    const resizableEl = useRef<HTMLDivElement>(null);

    const isMinHeight = height === MIN_HEIGHT;

    const rootCompanyId = queryParams[FILTER_NAMES.RootCompany];

    // Initial height setting
    useEffect(() => {
        detailsData && setHeight(defaultHeight);
    }, [detailsData, defaultHeight]);

    useEffect(() => {
        if (firstRender.current) {
            firstRender.current = false;
            return;
        }

        setDetailsData(null);
        // eslint-disable-next-line
    }, [rootCompanyId, reloadItems[tableName]]);

    const handleOnStart = (downEvent: any, isMouse: boolean) => {
        setIsDragging(true);

        const moveStart = isMouse ? "mousemove" : "touchmove";
        const moveEnd = isMouse ? "mouseup" : "touchend";

        initialPosition.current = isMouse
            ? downEvent.clientY
            : downEvent.touches[0].clientY;

        initialHeight.current = resizableEl.current!.offsetHeight;

        const handleMove = (moveEvent: any) => {
            const clientY = isMouse
                ? moveEvent.clientY
                : moveEvent.touches[0].clientY;

            const currentHeight =
                initialHeight.current +
                parseInt((initialPosition.current - clientY) as any);

            if (currentHeight >= MIN_HEIGHT && currentHeight <= tableHeight) {
                setHeight(currentHeight);
                setDefaultHeigth(currentHeight);
            }
        };

        const handleUp = () => {
            setIsDragging(false);
            document.removeEventListener(moveStart, handleMove);
        };

        document.addEventListener(moveStart, handleMove);
        document.addEventListener(moveEnd, handleUp, {
            once: true
        });
    };

    const handleFrameClick = () => {
        if (isMinHeight) {
            setHeight(initialDefaultHeight);
            setDefaultHeigth(initialDefaultHeight);

            localStorage.setItem(
                `${tableName}DetailsHeight`,
                String(initialDefaultHeight)
            );
        }
    };

    const handleToggleButton = () => {
        const newHeight =
            height > MIN_HEIGHT ? MIN_HEIGHT : initialDefaultHeight;

        setHeight(newHeight);
        setDefaultHeigth(newHeight);
        localStorage.setItem(`${tableName}DetailsHeight`, String(newHeight));
    };

    const showTransition = isDragging
        ? "none"
        : "background-color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms, height 225ms cubic-bezier(0.4, 0, 0.2, 1) 0ms";

    const backgroundOnHover = (color: COLORS) =>
        isMinHeight && !isDragging ? color : white;

    const renderContent = () => (
        <Fragment>
            <div
                ref={resizableEl}
                css={css({
                    height: `${height}px`,
                    boxSizing: "border-box",
                    cursor: isMinHeight ? "pointer" : "auto",
                    overflowY: "auto",
                    overflowX: "hidden",
                    pointerEvents: isDragging ? "none" : "auto",
                    position: "relative",
                    paddingTop: "16px",
                    boxShadow:
                        "0px -6px 14px -6px rgba(24, 39, 75, 0.08), 0px -10px 32px -4px rgba(24, 39, 75, 0.06)",
                    transition: showTransition,

                    "&:hover": {
                        background: backgroundOnHover(gray100)
                    },

                    "&:hover + div": {
                        right: isMinHeight ? "18px" : "0",
                        background: backgroundOnHover(gray100),

                        "& > svg": {
                            left: isMinHeight
                                ? "calc(50% - 3px)"
                                : "calc(50% - 12px)"
                        }
                    },

                    "&:hover + div + button": {
                        background: backgroundOnHover(gray200)
                    }
                })}
            >
                <div
                    className="remaining-height"
                    onClick={handleFrameClick}
                    css={css({
                        width: isDesktopDrawerOpen
                            ? "calc(100vw - 254px)"
                            : "calc(100vw - 86px)",
                        transition:
                            "width 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms",
                        height: "100%",
                        boxSizing: "border-box",
                        background: "inherit",
                        padding: "0 6px 12px 24px"
                    })}
                >
                    {idForDetailsData && detailsData ? (
                        children
                    ) : (
                        <div
                            css={css({
                                fontSize: "20px",
                                fontWeight: "600",
                                lineHeight: "24px",
                                letterSpacing: "0.15px",
                                color: textDark
                            })}
                        >
                            {isLoading ? (
                                <HeaderTitleLoader />
                            ) : (
                                t("Table##select row")
                            )}
                        </div>
                    )}
                </div>
            </div>

            <div
                onMouseDown={e => handleOnStart(e, true)}
                onTouchStart={e => handleOnStart(e, false)}
                css={css({
                    userSelect: "none",
                    position: "absolute",
                    top: "0",
                    left: "0",
                    right: "0",
                    borderTop: `1px solid ${gray200}`,
                    background: white,
                    height: "16px",
                    boxSizing: "border-box",
                    cursor: "row-resize",
                    pointerEvents: "auto",
                    transition:
                        "border-color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms, background-color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms, right 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms",

                    "& > svg": {
                        position: "relative",
                        left: "calc(50% - 12px)",
                        marginTop: "-1px",
                        transition:
                            "left 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms"
                    },

                    "&:hover": {
                        borderTop: `3px solid ${blue500}`,
                        background: white,

                        "& > svg": {
                            marginTop: "-3px"
                        }
                    }
                })}
            >
                <DragHandleIcon />
            </div>

            <TooltipGeneral
                placement={TOOLTIP_PLACEMENT.TopEnd}
                title={
                    isMinHeight
                        ? t("Button##maximize details")
                        : t("Button##minimize details")
                }
            >
                <Button
                    color={BUTTON_COLORS.White}
                    size={BUTTON_SIZES.Small}
                    variant={BUTTON_VARIANTS.IconOnly}
                    icon={<ExpandMoreRoundedIcon />}
                    onClick={handleToggleButton}
                    css={css({
                        color: textDark,
                        position: "absolute",
                        top: "16px",
                        right: "24px",
                        zIndex: "1",
                        transform: isMinHeight ? "rotate(180deg)" : "none"
                    })}
                />
            </TooltipGeneral>
        </Fragment>
    );

    return (
        <div
            css={css({
                position: "relative"
            })}
        >
            {renderContent()}
        </div>
    );
};

export default DesktopDetails;
