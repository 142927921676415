/** @jsxImportSource @emotion/react */
import { css } from "@emotion/react";

import { useContext } from "react";
import { useTranslation } from "react-i18next";

import CompanyLink from "./CompanyLink";

import { CompanyComponentProps } from "../types";

import TableContext from "../../../../context/table/tableContext";
import { LINKS } from "../../../../shared/constants/resources";
import { LinksTitleLoader, useCounterData } from "../../../../shared";

export const CompanyLinks = ({
    isReccuringLoading,
    titleCSS
}: CompanyComponentProps) => {
    const { idForDetailsData, detailsData } = useContext(TableContext);

    const { t } = useTranslation();

    const { isInitialLoad, isLoading, counter } = useCounterData(
        idForDetailsData!,
        undefined,
        undefined,
        isReccuringLoading
    );

    const { id, name } = detailsData;

    return (
        <>
            {isInitialLoad ? (
                <LinksTitleLoader />
            ) : (
                <div
                    css={css({ opacity: isLoading ? "0.4" : "1", ...titleCSS })}
                >
                    {t(`Table##links`)}
                </div>
            )}

            <div
                css={css({
                    display: "flex",
                    flexWrap: "wrap",
                    columnGap: "8px",
                    rowGap: "4px",
                    marginTop: "4px",
                    marginBottom: "24px"
                })}
            >
                {LINKS.map(link => {
                    return (
                        <CompanyLink
                            key={link.id}
                            data={link}
                            counter={counter}
                            isInitialLoad={isInitialLoad}
                            isLoading={isLoading}
                            companyId={id}
                            companyName={name}
                        />
                    );
                })}
            </div>
        </>
    );
};

export default CompanyLinks;
