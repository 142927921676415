import { HeaderLoaderProps } from "./types";

import { HeaderTitleLoader } from "../HeaderTitleLoader";

import { RectangleLoader } from "../../../../shared";

const HeaderLoader = ({ customStyle }: HeaderLoaderProps) => {
    return (
        <>
            <HeaderTitleLoader />

            <RectangleLoader
                width="301px"
                height="20px"
                customStyle={{ marginTop: "4px", ...customStyle }}
            />
        </>
    );
};

export { HeaderLoader };
