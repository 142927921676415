import { isNumericIntegerValue } from "../../../../../../../../../shared";

const useRevisionValidation = () => {
    const isValidRevision = (revision: string | null, isFinal: boolean) => {
        if (revision === null || revision === "") return true;

        const { length } = revision;

        const isCorrectValueLength = isFinal ? length === 2 : length <= 2;

        return isCorrectValueLength && isNumericIntegerValue(revision);
    };

    return { isValidRevision };
};

export { useRevisionValidation };
