import SingleLoader from "./SingleLoader";

const DetailsLoader = () => {
    return (
        <>
            <SingleLoader />
            <SingleLoader />
        </>
    );
};

export { DetailsLoader };
