/** @jsxImportSource @emotion/react */
import { css } from "@emotion/react";

import { useContext } from "react";
import { useTranslation } from "react-i18next";
import TableRowMui from "@mui/material/TableRow";
import TableCell from "@mui/material/TableCell";

import TaskStats from "../TaskStats";
import ApproveDiscard from "../ApproveDiscard";

import { TableItemInstanceProps } from "../../types";

import DateTime from "../../../DateTime";
import Badge, { BADGE_COLORS } from "../../../DesignComponents/Badge";
import {
    TOOLTIP_PLACEMENT,
    TooltipGeneral
} from "../../../DesignComponents/Tooltips";

import ThemeContext from "../../../../context/theme/themeContext";
import TableContext from "../../../../context/table/tableContext";
import {
    tableColumnDropShadow,
    tableColumnRightDropShadow,
    useOverflow
} from "../../../../shared";

const TableRow = ({
    data,
    isApprovalRequired,
    isApprovalBadgeRendered,
    scrollHorizontal
}: TableItemInstanceProps) => {
    const {
        id,
        task_type,
        type,
        file,
        objects,
        created_at,
        created_by,
        task_stats
    } = data;

    const {
        colorsFacelift: { gray50, gray200, textDark, textPlaceholder, white }
    } = useContext(ThemeContext);

    const { idType, detailsData, rows, setIdForDetailsData } =
        useContext(TableContext);

    const isSelected = detailsData && id === detailsData[idType];

    const { t } = useTranslation();
    const { measuredRef, isOverflowing } = useOverflow([rows]);

    const handleClick = () => {
        if (!isSelected) {
            setIdForDetailsData(id);
        }
    };

    return (
        <TableRowMui
            data-testid="table-row-component"
            onClick={handleClick}
            selected={isSelected}
            css={css({
                cursor: "pointer",

                td: {
                    background: white
                },

                "&:hover td": {
                    background: gray50
                }
            })}
        >
            <TableCell
                css={css([
                    {
                        position: "sticky",
                        left: "0",
                        maxWidth: "314px",

                        "& > div": {
                            overflowX: "hidden",
                            textOverflow: "ellipsis"
                        }
                    },
                    tableColumnRightDropShadow(scrollHorizontal)
                ])}
            >
                <div>
                    {task_type
                        ? t(`General##${task_type}`)
                        : t(`Task groups##bulk type##${type}`)}
                </div>

                {file && (
                    <TooltipGeneral
                        disableHoverListener={!isOverflowing}
                        placement={TOOLTIP_PLACEMENT.Top}
                        title={file.name}
                    >
                        <div ref={measuredRef}>({file.name})</div>
                    </TooltipGeneral>
                )}
            </TableCell>

            <TableCell
                css={css({
                    span: {
                        fontSize: "14px",
                        fontWeight: 600,
                        lineHeight: "20px",
                        letterSpacing: "0.1px",
                        color: textDark
                    },

                    "span:first-of-type": {
                        color: textPlaceholder,
                        marginRight: "5px"
                    }
                })}
            >
                <div>
                    <span>{t("General##id")}:</span>
                    <span>{id}</span>
                </div>
            </TableCell>

            <TableCell
                css={css({
                    maxWidth: "326px",

                    "& > div": {
                        overflowX: "hidden",
                        textOverflow: "ellipsis"
                    },

                    "& > div:first-of-type": {
                        marginBottom: "8px"
                    },

                    "span:first-of-type": {
                        color: textPlaceholder,
                        marginRight: "5px"
                    }
                })}
            >
                <div>
                    <span>{t("General##created at")}:</span>

                    <span>
                        <DateTime date={created_at} />
                    </span>
                </div>

                {created_by && (
                    <div>
                        <span>{t("General##created by")}:</span>
                        <span>{created_by.email}</span>
                    </div>
                )}
            </TableCell>

            <TableCell>
                {isApprovalBadgeRendered ? (
                    <Badge color={BADGE_COLORS.Light}>
                        {objects} {t("General##Pending approval")}
                    </Badge>
                ) : (
                    <TaskStats data={task_stats} />
                )}
            </TableCell>

            {isApprovalRequired ? (
                <TableCell
                    onClick={e => e.stopPropagation()}
                    css={css([
                        {
                            position: "sticky",
                            right: "0",
                            borderLeft: `1px solid ${gray200}`,
                            width: "0",
                            cursor: "auto",

                            "& > span": {
                                marginLeft: "12px"
                            }
                        },
                        tableColumnDropShadow(scrollHorizontal)
                    ])}
                >
                    <ApproveDiscard id={id} />
                </TableCell>
            ) : (
                <TableCell />
            )}
        </TableRowMui>
    );
};

export default TableRow;
