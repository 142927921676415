import {
    ParamsInitialState,
    ReloadItems,
    REMOVE_PARAMS_CONDITIONS
} from "../types";

import {
    Filter,
    FilterDropdownOptions,
    FilteredValue,
    FILTER_NAMES,
    isFilter,
    PER_PAGE,
    QueryParamName,
    QueryParams,
    TABLE_NAMES,
    NOT_FILTER_NAMES,
    COMPANY_FILTER,
    ROOT_COMPANY,
    isValidParamName,
    isFilterOrSearch,
    COMPANY_LEVELS,
    COMPANY_TYPES,
    isRootCompanyOrCompanyIdFilter,
    isSpecIdFilter,
    SPEC_ID_NOT_ASSIGNED_VALUE,
    ResourceLimitSettings
} from "../../../shared";

const isValidResource = (resource: TABLE_NAMES) => {
    const validResources = Object.values(TABLE_NAMES).filter(
        value =>
            value !== TABLE_NAMES.Changes &&
            value !== TABLE_NAMES.Tasks &&
            value !== TABLE_NAMES.OfflineLicenses
    );

    return validResources.includes(
        resource as
            | TABLE_NAMES.Companies
            | TABLE_NAMES.DesktopLicenses
            | TABLE_NAMES.DeviceGroups
            | TABLE_NAMES.Devices
            | TABLE_NAMES.Files
            | TABLE_NAMES.ProductCodes
            | TABLE_NAMES.TaskGroups
            | TABLE_NAMES.UserInvite
            | TABLE_NAMES.Users
    );
};

const isCorrectRowsValue = (value: any): value is PER_PAGE =>
    Object.values(PER_PAGE).includes(value);

const getRowsPerPageFromBrowser = (resource: TABLE_NAMES) => {
    const value = localStorage.getItem(`${resource}RowsPerPage`);

    if (isCorrectRowsValue(value)) {
        return value === "25" ? "" : value;
    } else {
        isValidResource(resource) &&
            localStorage.setItem(`${resource}RowsPerPage`, "25");

        return "";
    }
};

export const getRowsPerPageValue = (
    perPageValue: string | string[] | undefined,
    resource: TABLE_NAMES
) => {
    if (perPageValue) {
        if (isCorrectRowsValue(perPageValue)) {
            isValidResource(resource) &&
                localStorage.setItem(`${resource}RowsPerPage`, perPageValue);

            return perPageValue === "25" ? "" : perPageValue;
        } else {
            return getRowsPerPageFromBrowser(resource);
        }
    } else {
        return getRowsPerPageFromBrowser(resource);
    }
};

export const getCompanyFilterValue = (
    filterName: FILTER_NAMES.RootCompany | FILTER_NAMES.CompanyId,
    filterValue: string | string[] | undefined
) => {
    const isRootCompany = filterName === FILTER_NAMES.RootCompany;
    const localStorageName = isRootCompany ? ROOT_COMPANY : COMPANY_FILTER;

    if (filterValue) {
        return filterValue;
    } else {
        try {
            return JSON.parse(localStorage.getItem(localStorageName) || "");
        } catch (error) {
            return isRootCompany ? "" : [];
        }
    }
};

export const getActiveQueryParams = (
    isEmpty: boolean,
    companyFilterValue: string[],
    rowsPerPageValue: "50" | "100" | "" | undefined,
    state: ParamsInitialState,
    resetParams: boolean,
    updatedParams: QueryParams
) => {
    return isEmpty
        ? {
              [FILTER_NAMES.CompanyId]: companyFilterValue,
              [NOT_FILTER_NAMES.PerPage]: rowsPerPageValue
          }
        : {
              ...(resetParams ? {} : state.queryParams),
              ...updatedParams,
              [NOT_FILTER_NAMES.PerPage]: rowsPerPageValue
          };
};

const isWrongQueryParamValueLength = (
    key: QueryParamName,
    value: string | string[]
) => (key === NOT_FILTER_NAMES.Query ? value.length < 3 : value.length === 0);

export const removeNonValidQueryParams = (
    queryParamsArray: [QueryParamName, string | string[]][],
    activeQueryParams: QueryParams
) => {
    queryParamsArray.forEach(([key, value]) => {
        if (
            !isValidParamName(key) ||
            isWrongQueryParamValueLength(key, value)
        ) {
            delete activeQueryParams[key];
        }
    });
};

export const removeFilterAndSearchParams = (
    key: QueryParamName,
    removableParams: QueryParams,
    currentQueryParams: QueryParams
) => {
    if (
        isFilter(key) ||
        key === NOT_FILTER_NAMES.Query ||
        key === NOT_FILTER_NAMES.Page
    ) {
        removableParams[key] = "";
        delete currentQueryParams[key];
    }
};

export const distributedQueryParams = (params: QueryParams) => {
    const paramsCopy = { ...params };
    const rootCompanyFilter = params[FILTER_NAMES.RootCompany] || "";
    const companyFilter = params[FILTER_NAMES.CompanyId] || [];

    delete paramsCopy[FILTER_NAMES.RootCompany];
    delete paramsCopy[FILTER_NAMES.CompanyId];

    return {
        rootCompanyParams: rootCompanyFilter as string,
        companyParams: Array.isArray(companyFilter)
            ? companyFilter
            : [companyFilter],
        allOtherParams: paramsCopy
    };
};

export const distributeFilteredValues = (filteredValues?: FilteredValue) => {
    if (filteredValues?.filterName === FILTER_NAMES.CompanyId) {
        return {
            filteredValues: undefined,
            companyFilteredValues: filteredValues.value
        };
    } else {
        return {
            filteredValues,
            companyFilteredValues: undefined
        };
    }
};

export const saveToBrowser = (
    resource: TABLE_NAMES,
    paramsForSessionStorage: QueryParams,
    paramsForLocalStorageRootCompany: string,
    paramsForLocalStorageCompanySelector: string[]
) => {
    if (isValidResource(resource)) {
        sessionStorage.setItem(
            `${resource}Query`,
            JSON.stringify(paramsForSessionStorage)
        );
    }

    localStorage.setItem(
        ROOT_COMPANY,
        JSON.stringify(paramsForLocalStorageRootCompany)
    );

    localStorage.setItem(
        COMPANY_FILTER,
        JSON.stringify(paramsForLocalStorageCompanySelector)
    );
};

export const removeFromBrowser = (
    condition: REMOVE_PARAMS_CONDITIONS,
    resource: TABLE_NAMES,
    paramsForSessionStorage: QueryParams
) => {
    if (condition === REMOVE_PARAMS_CONDITIONS.OnlyCompanies) {
        localStorage.setItem(COMPANY_FILTER, JSON.stringify([]));
    } else {
        sessionStorage.setItem(
            `${resource}Query`,
            JSON.stringify(paramsForSessionStorage)
        );
    }
};

export const getResource = (
    isEmpty: boolean,
    currentResource: TABLE_NAMES | null
) => (isEmpty ? null : currentResource);

export const getRootCompanyAndCompanyId = (params: QueryParams) => {
    const allParams = { ...params };
    const allParamNames = Object.keys(allParams) as QueryParamName[];

    allParamNames.forEach(paramName => {
        const mustBeDeleted = !isRootCompanyOrCompanyIdFilter(paramName);

        if (mustBeDeleted) {
            delete allParams[paramName];
        }
    });

    return allParams;
};

export const getFilteredValues = (
    currentValues: FilteredValue[],
    newValue: FilteredValue | undefined
) => {
    if (newValue) {
        if (newValue.value?.length) {
            const filterIndex = currentValues.findIndex(
                val => val.filterName === newValue.filterName
            );

            if (filterIndex === -1) {
                return [...currentValues, newValue];
            } else {
                currentValues[filterIndex] = newValue;
                return currentValues;
            }
        } else {
            return currentValues.filter(
                currentValue => currentValue.filterName !== newValue.filterName
            );
        }
    } else {
        return currentValues;
    }
};

export const getCompanyFilteredValues = (
    currentValues: Filter[],
    newValue: Filter[] | undefined
) => {
    if (newValue) {
        return newValue.length ? newValue : [];
    } else {
        return currentValues;
    }
};

export const getFilterDropdownOptions = (
    currentOptions: FilterDropdownOptions,
    newOptions: FilterDropdownOptions | Filter[] | undefined
) => {
    if (newOptions) {
        const updatedValue = Array.isArray(newOptions)
            ? { [FILTER_NAMES.CompanyId]: newOptions }
            : newOptions;

        return { ...currentOptions, ...updatedValue };
    } else {
        return currentOptions;
    }
};

export const onlyCompaniesOrAllExceptCompanies = (onlyCompanies: boolean) =>
    onlyCompanies
        ? REMOVE_PARAMS_CONDITIONS.OnlyCompanies
        : REMOVE_PARAMS_CONDITIONS.AllFiltersExceptCompanies;

export const removeParams = (
    paramNames: QueryParamName[],
    updatedQueryParams: QueryParams,
    condition: REMOVE_PARAMS_CONDITIONS
) => {
    const removableParams: QueryParams = {};

    const getCondition = (key: QueryParamName) => {
        switch (condition) {
            case REMOVE_PARAMS_CONDITIONS.AllFiltersExceptCompanies:
                return (
                    isFilter(key) &&
                    key !== FILTER_NAMES.CompanyId &&
                    key !== FILTER_NAMES.RootCompany
                );
            case REMOVE_PARAMS_CONDITIONS.OnlyCompanies:
                return key === FILTER_NAMES.CompanyId;
            case REMOVE_PARAMS_CONDITIONS.AllForRootCompanyChange:
                return (
                    (isFilterOrSearch(key) &&
                        key !== FILTER_NAMES.RootCompany) ||
                    key === NOT_FILTER_NAMES.Page ||
                    key === NOT_FILTER_NAMES.Selected
                );
            default:
                return (
                    isFilterOrSearch(key) &&
                    key !== FILTER_NAMES.RootCompany &&
                    key !== FILTER_NAMES.CompanyId
                );
        }
    };

    paramNames.forEach(key => {
        const currentCondition = getCondition(key);

        if (currentCondition) {
            removableParams[key] = "";
            delete updatedQueryParams[key];
        }
    });

    return removableParams;
};

export const removeNonExistentQueryParams = (
    filteredValues: FilteredValue[],
    queryParams: QueryParams
) => {
    const removableParams: QueryParams = {};

    filteredValues.forEach(({ filterName, value }) => {
        if (value.length === 0) {
            removableParams[filterName] = "";
            delete queryParams[filterName];
        } else {
            const currentFilterParams = queryParams[filterName];

            if (currentFilterParams) {
                const currentFilterParamsArray = Array.isArray(
                    currentFilterParams
                )
                    ? currentFilterParams
                    : [currentFilterParams];

                const filteredArray = currentFilterParamsArray.filter(param => {
                    const isSpecIdNotAssigned =
                        isSpecIdFilter(filterName) &&
                        (param === "null" || param === "0" || param === "1");

                    const currentParam = isSpecIdNotAssigned
                        ? SPEC_ID_NOT_ASSIGNED_VALUE
                        : param;

                    const paramExists = value.some(
                        singleValue =>
                            String(singleValue.id) ===
                            decodeURIComponent(currentParam)
                    );

                    return paramExists;
                });

                removableParams[filterName] = filteredArray;
                queryParams[filterName] = filteredArray;
            }
        }
    });

    return removableParams;
};

export const getUpdatedReloadItems = (
    reloadItems: ReloadItems,
    payload: TABLE_NAMES | TABLE_NAMES[]
) => {
    const currentReloadItems = { ...reloadItems };

    const tableNames = Array.isArray(payload) ? payload : [payload];

    tableNames.forEach(tableName => {
        currentReloadItems[tableName] = currentReloadItems[tableName] + 1;
    });

    return currentReloadItems;
};

export const getCompanyLevel = (
    newCompanyLevel: COMPANY_LEVELS | null,
    currentCompanyLevel: COMPANY_LEVELS
) => newCompanyLevel || currentCompanyLevel;

export const getCompanyType = (
    newCompanyType: COMPANY_TYPES | null,
    currentCompanyType: COMPANY_TYPES
) => newCompanyType || currentCompanyType;

export const getRootClientCompanyId = (
    newRootClientCompanyId: number | null | undefined,
    currentRootClientCompanyId: number | null
) =>
    newRootClientCompanyId === undefined
        ? currentRootClientCompanyId
        : newRootClientCompanyId;

export const getRootCompany = (
    filterDropdownOptions: FilterDropdownOptions,
    currentRootCompany: Filter | null,
    rootCompanyId?: string
) => {
    if (rootCompanyId !== undefined) {
        const rootOptions = filterDropdownOptions[FILTER_NAMES.RootCompany];

        const rootCompany = rootOptions?.find(
            option => String(option.id) === rootCompanyId
        );

        return rootCompany || currentRootCompany;
    }

    return currentRootCompany;
};

export const getRootCustomResourceLimitSettings = (
    newSettings: ResourceLimitSettings | null | undefined,
    currentSettings: ResourceLimitSettings | null
) => (newSettings === undefined ? currentSettings : newSettings);

export const setQueryParamsHelperFunctions = (params: QueryParams) => {
    const getSearchParams = () => {
        if (params.query) {
            return params.query.length >= 3
                ? { [NOT_FILTER_NAMES.Query]: params.query }
                : { [NOT_FILTER_NAMES.Query]: "" };
        }

        return {};
    };

    const getParamsApprovable = () => {
        if (params.approvable) {
            return Array.isArray(params.approvable)
                ? { approvable: params.approvable[0] || "" }
                : { approvable: params.approvable };
        }

        return {};
    };

    return { getSearchParams, getParamsApprovable };
};
