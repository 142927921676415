import { useState } from "react";

import Header from "./Header";
import Tasks from "./Tasks";
import { TaskTableData } from "./types";

import { IndividualDetailsProps } from "../types";

const TaskGroupDetails = ({ isLoading }: IndividualDetailsProps) => {
    const [taskTableData, setTaskTableData] = useState<TaskTableData>({
        rows: [],
        totalRows: 0,
        isInnerTableLoading: true
    });

    const getTaskTableData = (data: TaskTableData) =>
        setTaskTableData({ ...taskTableData, ...data });

    return (
        <>
            <Header isLoading={isLoading} taskTableData={taskTableData} />
            <Tasks getTaskTableData={getTaskTableData} />
        </>
    );
};

export default TaskGroupDetails;
