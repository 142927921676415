import { useContext } from "react";

import { TaskProps } from "./types";

import { useGetSingleTaskGroupEndpoint } from "../../TaskGroups/hooks";
import useTaskInlineActions from "../../Actions/InlineActions/hooks/useTaskInlineActions";
import Table from "../../Table";
import TaskDetails from "../../Actions/dialogs/Tasks/TaskDetails";

import ParamsContext from "../../../context/params/paramsContext";
import TableContext from "../../../context/table/tableContext";
import {
    BatchTaskStatus,
    ID_TYPE,
    SORT_RESOURCES,
    TABLE_NAMES,
    TASK_COLUMNS as COLUMNS,
    ORDERS,
    FILTER_NAMES,
    NOT_FILTER_NAMES,
    TABLE_NAMES_WITH_FILTERS,
    useRecurringLoading
} from "../../../shared";

const Tasks = ({ getTaskTableData }: TaskProps) => {
    const { queryParams, companyFilteredValues } = useContext(ParamsContext);

    const {
        detailsData,
        idForDetailsData,
        reloadDetailsData,
        updateSingleRowData
    } = useContext(TableContext);

    const { getSingleTaskGroupEndpoint } = useGetSingleTaskGroupEndpoint();

    const { inlineActions, taskData, isOpen, closeDialog, setTaskData } =
        useTaskInlineActions();

    const { isReccuringLoading } = useRecurringLoading();

    const { task_stats } = detailsData;

    const selectableCount: number = task_stats.reduce(
        (previous: number, { status_id, count }: BatchTaskStatus) => {
            const isSelectable = status_id === 0 || status_id === 1;
            const currentCount = isSelectable ? count : 0;

            return previous + currentCount;
        },
        0
    );

    const rootCompanyId = queryParams[FILTER_NAMES.RootCompany];

    const filteredCompanies = companyFilteredValues?.map(company =>
        company.id.toString()
    );

    return (
        <>
            <Table
                data-testid="tasks-table"
                name={TABLE_NAMES.Tasks}
                nameForFilters={TABLE_NAMES_WITH_FILTERS.TaskGroupDetailTasks}
                isInnerTable
                initialColumns={COLUMNS}
                inlineActions={inlineActions}
                idType={ID_TYPE.Id}
                selectableCount={selectableCount}
                outerDetailsData={{
                    detailsData,
                    endpointForSingleUpdate: getSingleTaskGroupEndpoint(
                        idForDetailsData!
                    ),
                    reloadDetailsData,
                    isReccuringLoading,
                    updateMainRowData: updateSingleRowData
                }}
                innerTableQueryParams={{
                    [FILTER_NAMES.BatchId]: String(idForDetailsData),
                    [NOT_FILTER_NAMES.Order]: ORDERS.Descending,
                    [NOT_FILTER_NAMES.Sort]: SORT_RESOURCES.Id,
                    [FILTER_NAMES.RootCompany]: rootCompanyId,
                    [FILTER_NAMES.CompanyId]: filteredCompanies
                }}
                getInnerTableData={getTaskTableData}
            />

            <TaskDetails
                data={taskData}
                isOpen={isOpen}
                close={closeDialog}
                setTaskData={setTaskData}
            />
        </>
    );
};

export default Tasks;
