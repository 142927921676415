/** @jsxImportSource @emotion/react */
import { css } from "@emotion/react";

import { useContext } from "react";
import CircularProgress from "@mui/material/CircularProgress";

import { useTableData, useTableDetailHeights } from "./hooks";
import TableMobile from "./TableMobile";
import TableDesktop from "./TableDesktop";
import { TableProps } from "./types";

import Toolbar from "../Toolbar";
import TableLoader from "../Loaders/TableLoader";
import TableEmpty from "../TableEmpty";
import TableDetails from "../TableDetails";

import TableContext from "../../context/table/tableContext";
import { noop, TABLE_NAMES, useMediaQueries, useScroll } from "../../shared";

const Table = ({ customStyle, onScroll = true }: TableProps) => {
    const {
        hasDetails,
        rows,
        isTableLoading,
        tableName,
        isInnerTable,
        customData
    } = useContext(TableContext);

    const { tableSectionRef, detailsHeights, setDefaultHeigth } =
        useTableDetailHeights();

    useTableData();

    const { toMd } = useMediaQueries();
    const { scrollHorizontal, handleScrollHorizontal } = useScroll();

    const renderTableData = () => {
        if (rows.length === 0) {
            return isTableLoading ? <TableLoader /> : <TableEmpty />;
        }

        return (
            <div
                ref={isInnerTable ? null : tableSectionRef}
                className="remaining-height"
                data-testid="table"
            >
                <div
                    className="remaining-height"
                    css={css({
                        position: "relative",
                        overflow: "hidden"
                    })}
                >
                    {isTableLoading && (
                        <div
                            css={css({
                                display: "flex",
                                justifyContent: "center",
                                position: "absolute",
                                top: "88px",
                                width: "100%",
                                zIndex: "4"
                            })}
                        >
                            <CircularProgress />
                        </div>
                    )}

                    <div
                        onScroll={onScroll ? handleScrollHorizontal : noop}
                        css={css({
                            position: "absolute",
                            top: "0",
                            left: "0",
                            right: "0",
                            bottom: "0",
                            overflow: "auto",
                            scrollbarWidth: "thin",

                            "&:active": {
                                pointerEvents: isTableLoading ? "none" : "auto"
                            }
                        })}
                    >
                        {toMd ? (
                            <TableMobile />
                        ) : (
                            <TableDesktop scrollHorizontal={scrollHorizontal} />
                        )}
                    </div>
                </div>

                {hasDetails && (
                    <TableDetails
                        heights={detailsHeights}
                        setDefaultHeigth={setDefaultHeigth}
                    />
                )}
            </div>
        );
    };

    return (
        <section
            className="remaining-height"
            css={css({
                margin: isInnerTable ? "0 -24px" : "0",
                ...customStyle
            })}
        >
            {!customData && (
                <div
                    css={css({
                        padding:
                            tableName === TABLE_NAMES.Tasks ? "0 24px" : "0"
                    })}
                >
                    <Toolbar />
                </div>
            )}

            {renderTableData()}
        </section>
    );
};

export default Table;
