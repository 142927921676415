import { ChangeEvent, useState } from "react";
import { useTranslation } from "react-i18next";

import { useRevisionValidation } from "./hooks";
import { RevisionProps } from "./types";

import InputField, {
    INPUT_FIELD_SIZES
} from "../../../../../../../DesignComponents/InputField";

const Revision = ({ value, changeValue }: RevisionProps) => {
    const { t } = useTranslation();
    const { isValidRevision } = useRevisionValidation();

    const [error, setError] = useState<string | null>(null);

    const handleChange = (e: ChangeEvent<HTMLInputElement>) => {
        const newValue = e.target.value;

        handleError(newValue, false);
        changeValue(newValue);
    };

    const handleError = (value: string, isFinal: boolean) => {
        const isCorrectValue = isValidRevision(value, isFinal);

        const errorValue = !isCorrectValue
            ? t("Dialog##revision value error")
            : null;

        setError(errorValue);
    };

    return (
        <InputField
            size={INPUT_FIELD_SIZES.Medium}
            fullWidth
            value={value}
            labelLeft={t("Dialog##revision")}
            placeholder={`${t("Dialog##example")} 01`}
            errorText={error}
            onChange={handleChange}
            onBlur={() => handleError(value, true)}
            customStyle={{ marginTop: "16px" }}
        />
    );
};

export default Revision;
