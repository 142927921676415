import { useState, useEffect, useContext, useRef } from "react";

import { getReloadStatus, getStatisticsEndpoint } from "./functions";

import {
    API_IS_BEING_CANCELLED,
    useApi,
    useIsMounted,
    LINK_IDS,
    FILTER_NAMES,
    FilterId
} from "../../";
import { COUNTER_INITIAL_STATE } from "../../constants/resources";

import ParamsContext from "../../../context/params/paramsContext";
import TableContext from "../../../context/table/tableContext";
import { CounterData } from "../../../components/TableDetails/CompanyDetails/types/interfaces";

export const useCounterData = (
    id: FilterId[] | number | undefined,
    doesHaveSameRootId?: boolean,
    doesIncludeHierarchy?: boolean,
    isReccuringLoading?: boolean
) => {
    const { queryParams } = useContext(ParamsContext);
    const { reloadDetailsData } = useContext(TableContext);

    const { cancelSource, isCanceled, getData, handleResponse } = useApi();
    const isMounted = useIsMounted();
    const isInitialLoad = useRef(true);

    const [isLoading, setLoading] = useState<boolean>(true);
    const [counter, setCounter] = useState<CounterData>(COUNTER_INITIAL_STATE);

    const { Users, Groups, Files, Devices, Tasks, ChildCompanies } = LINK_IDS;

    const rootCompanyId = queryParams[FILTER_NAMES.RootCompany];

    const statisticsEndpoint = getStatisticsEndpoint(
        rootCompanyId,
        doesHaveSameRootId,
        id,
        doesIncludeHierarchy
    );

    const shouldReload = getReloadStatus(reloadDetailsData, isReccuringLoading);

    useEffect(() => {
        const getCounterData = async () => {
            try {
                !isReccuringLoading && setLoading(true);

                const {
                    data: {
                        user_count,
                        group_count,
                        file_count,
                        device_count,
                        task_count,
                        company_count
                    }
                } = await getData(statisticsEndpoint);

                setCounter({
                    [Users]: user_count,
                    [Groups]: group_count,
                    [Files]: file_count,
                    [Devices]: device_count,
                    [Tasks]: task_count,
                    [ChildCompanies]: company_count
                });

                !isReccuringLoading && setLoading(false);
            } catch (error) {
                if (isMounted && !isCanceled(error)) {
                    handleResponse(error);
                    setCounter(COUNTER_INITIAL_STATE);
                    !isReccuringLoading && setLoading(false);
                }
            }

            if (isInitialLoad.current) {
                isInitialLoad.current = false;
            }
        };

        if (isMounted && id !== undefined) {
            getCounterData();
        }

        return () => {
            cancelSource.cancel(API_IS_BEING_CANCELLED);
        };

        // eslint-disable-next-line
    }, [isMounted, statisticsEndpoint, shouldReload]);

    return { isInitialLoad: isInitialLoad.current, isLoading, counter };
};
