import { useReducer, useMemo, useCallback } from "react";

import ParamsContext from "./paramsContext";
import ParamsReducer from "./paramsReducer";
import {
    SET_RESOURCE,
    RESET_FILTER_PARAMS,
    RESET_ALL_PARAMS,
    SET_QUERY_PARAMS,
    SET_FILTERED_VALUES,
    SET_COMPANY_FILTERED_VALUES,
    UPDATE_FILTER_PARAMS,
    RELOAD_ITEMS,
    TOGGLE_TABLE_ROW_DESELECTION,
    CHANGE_ROOT_COMPANY,
    CHANGE_ROOT_COMPANY_LEVEL,
    TRIGGER_ROOT_COMPANY,
    CHANGE_CUSTOM_RESOURCE_LIMITS
} from "./paramsActions";
import {
    distributeFilteredValues,
    setQueryParamsHelperFunctions
} from "./functions";
import { ParamsStateProps, SetQueryParamsOptions } from "./types";

import {
    QueryParams,
    FilteredValue,
    TABLE_NAMES,
    Filter,
    FilterDropdownOptions,
    NOT_FILTER_NAMES,
    FILTER_NAMES,
    COMPANY_LEVELS,
    useApi,
    ENDPOINTS,
    COMPANY_TYPES,
    updateParamsWithCorrectSpecIdValue,
    getCustomResourceLimits,
    ResourceLimitSettings
} from "../../shared";

const ParamsState = ({ children }: ParamsStateProps) => {
    const initialState = {
        resource: null,
        filteredItemsCount: 0,
        queryParams: {},
        filterDropdownOptions: {},
        filterQueryParams: {},
        filteredValues: [],
        companyFilteredValues: [],
        stringParams: "",
        tableRowDeselectionToggle: false,
        rootCompany: null,
        rootCompanyLevel: COMPANY_LEVELS.Basic,
        rootCompanyType: COMPANY_TYPES.Client,
        rootClientCompanyId: null,
        rootCustomResourceLimitSettings: null,
        reloadRootCompany: 0,
        reloadItems: {
            [TABLE_NAMES.Changes]: 0,
            [TABLE_NAMES.Companies]: 0,
            [TABLE_NAMES.Devices]: 0,
            [TABLE_NAMES.Files]: 0,
            [TABLE_NAMES.DeviceGroups]: 0,
            [TABLE_NAMES.DesktopLicenses]: 0,
            [TABLE_NAMES.ProductCodes]: 0,
            [TABLE_NAMES.TaskGroups]: 0,
            [TABLE_NAMES.Tasks]: 0,
            [TABLE_NAMES.UserInvite]: 0,
            [TABLE_NAMES.Users]: 0,
            [TABLE_NAMES.OfflineLicenses]: 0
        }
    };

    const [state, dispatch] = useReducer(ParamsReducer, initialState);
    const { getData } = useApi();

    const setResource = (resource: TABLE_NAMES) =>
        dispatch({ type: SET_RESOURCE, payload: resource });

    const setQueryParams = (parameters: SetQueryParamsOptions) => {
        const {
            params,
            resetPage,
            filteredValues,
            resetParams,
            removeFilterOptions,
            removeSelected,
            rootInfo
        } = parameters;

        const { getSearchParams, getParamsApprovable } =
            setQueryParamsHelperFunctions(params);

        const paramsWithCorrectSpecId =
            updateParamsWithCorrectSpecIdValue(params);

        const updatedParams = {
            ...paramsWithCorrectSpecId,
            ...getSearchParams(),
            ...getParamsApprovable(),
            ...(Boolean(resetPage) && {
                [NOT_FILTER_NAMES.Page]: "",
                [NOT_FILTER_NAMES.Selected]: ""
            }),
            ...(Boolean(removeSelected) && {
                [NOT_FILTER_NAMES.Selected]: ""
            })
        };

        dispatch({
            type: SET_QUERY_PARAMS,
            payload: {
                rootCompany: rootInfo?.name ?? null,
                rootCompanyLevel: rootInfo?.level ?? null,
                rootCompanyType: rootInfo?.type ?? null,
                rootClientCompanyId: rootInfo?.rootClientCompanyId,
                rootCustomResourceLimitSettings:
                    rootInfo?.customResourceLimitSettings,
                resetParams: Boolean(resetParams),
                removeFilterOptions: Boolean(removeFilterOptions),
                updatedParams,
                ...distributeFilteredValues(filteredValues)
            }
        });
    };

    const changeRootCompany = useCallback(
        async (rootId: string, additionalParams?: QueryParams) => {
            const updatedParams = {
                [FILTER_NAMES.RootCompany]: rootId,
                ...(additionalParams || {})
            };

            try {
                const rootCompanyAttributes = await getData(
                    `${ENDPOINTS.Companies}/${rootId}/attributes`
                );

                const customResourceLimits = await getCustomResourceLimits(
                    rootId,
                    getData
                );

                dispatch({
                    type: CHANGE_ROOT_COMPANY,
                    payload: {
                        params: updatedParams,
                        rootCompanyLevel:
                            rootCompanyAttributes.data.level || null,
                        rootCompanyType:
                            rootCompanyAttributes.data.type || null,
                        rootClientCompanyId:
                            rootCompanyAttributes.data.root_client_company_id,
                        rootCustomResourceLimitSettings: customResourceLimits
                    }
                });
            } catch (error) {
                dispatch({
                    type: CHANGE_ROOT_COMPANY,
                    payload: {
                        params: updatedParams,
                        rootCompanyLevel: null,
                        rootCompanyType: null,
                        rootClientCompanyId: undefined,
                        rootCustomResourceLimitSettings: null
                    }
                });
            }
        },
        [getData]
    );

    const changeRootCompanyLevel = useCallback(
        (level: COMPANY_LEVELS) =>
            dispatch({ type: CHANGE_ROOT_COMPANY_LEVEL, payload: level }),
        []
    );

    const changeCustomResourceLimits = (
        settings: ResourceLimitSettings | null
    ) => dispatch({ type: CHANGE_CUSTOM_RESOURCE_LIMITS, payload: settings });

    const triggerRootCompany = (hasTableReload: boolean) =>
        dispatch({ type: TRIGGER_ROOT_COMPANY, payload: hasTableReload });

    const setFilteredValues = (
        data: FilteredValue[],
        filterDropdownOptions?: FilterDropdownOptions
    ) =>
        dispatch({
            type: SET_FILTERED_VALUES,
            payload: {
                data,
                filterDropdownOptions
            }
        });

    const setCompanyFilteredValues = (
        filteredValues: Filter[],
        filterDropdownOptions: FilterDropdownOptions
    ) => {
        dispatch({
            type: SET_COMPANY_FILTERED_VALUES,
            payload: {
                filteredValues,
                filterDropdownOptions
            }
        });
    };

    const updateFilterParams = (
        updatedParams: QueryParams,
        filteredValues: FilteredValue
    ) =>
        dispatch({
            type: UPDATE_FILTER_PARAMS,
            payload: {
                updatedParams,
                ...distributeFilteredValues(filteredValues)
            }
        });

    const resetFilterParams = (onlyCompanies?: boolean) =>
        dispatch({
            type: RESET_FILTER_PARAMS,
            payload: Boolean(onlyCompanies)
        });

    const resetAllParams = () => dispatch({ type: RESET_ALL_PARAMS });

    const toggleTableRowDeselection = () =>
        dispatch({ type: TOGGLE_TABLE_ROW_DESELECTION });

    const setReloadItems = (resource: TABLE_NAMES | TABLE_NAMES[]) =>
        dispatch({ type: RELOAD_ITEMS, payload: resource });

    const value = useMemo(
        () => ({
            resource: state.resource,
            filteredItemsCount: state.filteredItemsCount,
            queryParams: state.queryParams,
            filterDropdownOptions: state.filterDropdownOptions,
            filterQueryParams: state.filterQueryParams,
            filteredValues: state.filteredValues,
            companyFilteredValues: state.companyFilteredValues,
            stringParams: state.stringParams,
            tableRowDeselectionToggle: state.tableRowDeselectionToggle,
            rootCompany: state.rootCompany,
            rootCompanyLevel: state.rootCompanyLevel,
            rootCompanyType: state.rootCompanyType,
            rootClientCompanyId: state.rootClientCompanyId,
            rootCustomResourceLimitSettings:
                state.rootCustomResourceLimitSettings,
            reloadRootCompany: state.reloadRootCompany,
            reloadItems: state.reloadItems,
            setResource,
            resetFilterParams,
            resetAllParams,
            toggleTableRowDeselection,
            setQueryParams,
            changeRootCompany,
            changeRootCompanyLevel,
            changeCustomResourceLimits,
            triggerRootCompany,
            setFilteredValues,
            setCompanyFilteredValues,
            updateFilterParams,
            setReloadItems
        }),
        [
            state.resource,
            state.filteredItemsCount,
            state.queryParams,
            state.filterDropdownOptions,
            state.filterQueryParams,
            state.filteredValues,
            state.companyFilteredValues,
            state.stringParams,
            state.tableRowDeselectionToggle,
            state.rootCompany,
            state.rootCompanyLevel,
            state.rootCompanyType,
            state.rootClientCompanyId,
            state.rootCustomResourceLimitSettings,
            state.reloadRootCompany,
            state.reloadItems,
            changeRootCompany,
            changeRootCompanyLevel
        ]
    );

    return (
        <ParamsContext.Provider value={value}>
            {children}
        </ParamsContext.Provider>
    );
};

export default ParamsState;
