/** @jsxImportSource @emotion/react */
import { css } from "@emotion/react";

import { useContext, useEffect } from "react";
import {
    NavigationType,
    useNavigate,
    useNavigationType
} from "react-router-dom";
import useResizeObserver from "use-resize-observer";
import MenuIcon from "@mui/icons-material/MenuRounded";
import Backdrop from "@mui/material/Backdrop";
import CircularProgress from "@mui/material/CircularProgress";

import MaintenanceBanner from "./MaintenanceBanner";
import Controls from "./Controls";
import DesktopDrawer from "./DesktopDrawer/DesktopDrawer";
import MobileDrawer from "./MobileDrawer";
import { getCompanyFilteredValues } from "./functions";

import Logo from "../Logo";
import CompanyLevelDialog from "../CompanyLevel";
import CompanyFilter from "../Filters/CompanyFilter";
import Button, {
    BUTTON_COLORS,
    BUTTON_SIZES,
    BUTTON_VARIANTS
} from "../DesignComponents/Button";
import FindTool from "../Filters/FindTool";

import ThemeContext from "../../context/theme/themeContext";
import AuthContext from "../../context/auth/authContext";
import ParamsContext from "../../context/params/paramsContext";
import RoutesContext from "../../context/routes/routesContext";
import { useBackgroundActions } from "../../context/notifications/hooks";
import {
    useMediaQueries,
    useWindowLocation,
    useParamsFromUrlAndBrowser,
    ROUTES,
    FILTER_NAMES
} from "../../shared";

const Navbar = () => {
    const {
        colorsFacelift: { gray200, textDark, white }
    } = useContext(ThemeContext);

    const { isLoading, isSupportUser } = useContext(AuthContext);

    const {
        filterDropdownOptions,
        queryParams,
        reloadRootCompany,
        setQueryParams
    } = useContext(ParamsContext);

    const {
        isMaintenanceBannerOpen,
        isCompanyLevelDialogOpen,
        toggleCompanyLevelDialogOpen,
        setMobileDrawerStatus
    } = useContext(RoutesContext);

    const { pathname } = useWindowLocation();
    const { toMd, fromMd } = useMediaQueries();
    const { currentParams } = useParamsFromUrlAndBrowser();
    useBackgroundActions();

    const { ref: topBannersRef, height: topBannersHeight } =
        useResizeObserver<HTMLElement>();

    const navigate = useNavigate();
    const navigationType = useNavigationType();

    const rootCompanyId = queryParams[FILTER_NAMES.RootCompany] as
        | string
        | undefined;

    useEffect(() => {
        if (pathname !== ROUTES.Login) {
            const companyFilteredValues = getCompanyFilteredValues(
                filterDropdownOptions,
                currentParams
            );

            if (rootCompanyId) {
                const isPopAction = navigationType === NavigationType.Pop;

                setQueryParams({
                    params: {
                        ...currentParams,
                        // when using the browser's back/forward buttons
                        ...(isPopAction
                            ? {}
                            : {
                                  [FILTER_NAMES.RootCompany]: rootCompanyId
                              })
                    },
                    filteredValues: companyFilteredValues,
                    resetParams: true
                });
            }
        }

        // eslint-disable-next-line
    }, [rootCompanyId, pathname]);

    useEffect(() => {
        setMobileDrawerStatus(false);
        // eslint-disable-next-line
    }, [pathname, fromMd]);

    return (
        <>
            <MaintenanceBanner ref={topBannersRef} />

            <header
                css={css({
                    position: "sticky",
                    top: "0",
                    zIndex: "1",
                    background: white
                })}
            >
                <div
                    css={css({
                        padding: "10px 24px 9px 24px",
                        borderBottom: `1px solid ${gray200}`,
                        display: "flex",
                        justifyContent: "space-between",
                        alignItems: "center"
                    })}
                >
                    <Logo
                        hasSymbolOnly={isSupportUser}
                        css={css({
                            cursor: "pointer"
                        })}
                        onClick={() => {
                            pathname !== ROUTES.Dashboard &&
                                navigate(ROUTES.Dashboard);
                        }}
                    />

                    <div
                        css={css({
                            display: "flex",
                            alignItems: "center"
                        })}
                    >
                        {isSupportUser && (
                            <FindTool
                                css={css({
                                    marginRight: toMd ? "4px" : "8px"
                                })}
                            />
                        )}

                        <CompanyFilter
                            key={`company-filter-${reloadRootCompany}`}
                        />

                        {toMd ? (
                            <Button
                                id="mobileDrawerBtn"
                                color={BUTTON_COLORS.White}
                                size={BUTTON_SIZES.Small}
                                variant={BUTTON_VARIANTS.IconOnly}
                                icon={
                                    <MenuIcon
                                        css={css({
                                            color: textDark
                                        })}
                                    />
                                }
                                onClick={() => setMobileDrawerStatus(true)}
                                css={css({
                                    marginLeft: toMd ? "4px" : "8px"
                                })}
                            />
                        ) : (
                            <Controls />
                        )}
                    </div>
                </div>
            </header>

            {toMd ? (
                <MobileDrawer />
            ) : (
                <DesktopDrawer
                    topBannersHeight={
                        isMaintenanceBannerOpen ? topBannersHeight : 0
                    }
                />
            )}

            <Backdrop
                open={isLoading}
                sx={{
                    zIndex: "9999"
                }}
            >
                <CircularProgress
                    sx={{
                        color: white
                    }}
                />
            </Backdrop>

            <CompanyLevelDialog
                isOpen={isCompanyLevelDialogOpen}
                close={() => toggleCompanyLevelDialogOpen(false, false)}
            />
        </>
    );
};

export default Navbar;
