/** @jsxImportSource @emotion/react */
import { css } from "@emotion/react";

import { useContext, useState, SetStateAction } from "react";
import { useTranslation } from "react-i18next";
import Badge from "@mui/material/Badge";
import NotificationsIcon from "@mui/icons-material/NotificationsNoneRounded";

import NotificationsMobile from "./NotificationsMobile";
import NotificationsDesktop from "./NotificationsDesktop";

import { useReceiveNotifications } from "../hooks";
import { NotificationsProps } from "../types";

import ThemeContext from "../../../context/theme/themeContext";
import NotificationsContext from "../../../context/notifications/notificationsContext";
import Button, {
    BUTTON_COLORS,
    BUTTON_SIZES,
    BUTTON_VARIANTS
} from "../../../components/DesignComponents/Button";
import { MenuAnchorEl, useMediaQueries } from "../../../shared";

const Notifications = ({ handleDrawer }: NotificationsProps) => {
    const {
        colorsFacelift: { gray100, gray200, gray300, red600, white }
    } = useContext(ThemeContext);

    const { unreadCount } = useContext(NotificationsContext);

    const { t } = useTranslation();
    const { toMd } = useMediaQueries();
    useReceiveNotifications();

    const [anchorEl, setAnchorEl] = useState<MenuAnchorEl>(null);
    const [isDialogOpen, setIsDialogOpen] = useState(false);

    const openMenu = (e: { currentTarget: SetStateAction<MenuAnchorEl> }) =>
        setAnchorEl(e.currentTarget);

    const closeMenu = () => setAnchorEl(null);

    const openDialog = () => setIsDialogOpen(true);

    const closeDialog = () => {
        handleDrawer && handleDrawer(false);
        setIsDialogOpen(false);
    };

    return (
        <>
            <Button
                disableRipple={toMd}
                fullWidth={toMd}
                color={BUTTON_COLORS.White}
                size={BUTTON_SIZES.Small}
                variant={BUTTON_VARIANTS.IconLeft}
                icon={
                    <Badge
                        badgeContent={unreadCount}
                        sx={{
                            "& .MuiBadge-badge": {
                                backgroundColor: red600,
                                color: white,
                                fontSize: "12px",
                                fontWeight: "700",
                                letterSpacing: "0.4px",
                                padding: "0 5px"
                            }
                        }}
                    >
                        <NotificationsIcon />
                    </Badge>
                }
                onClick={toMd ? openDialog : openMenu}
                css={css({
                    "&:hover": {
                        background: toMd ? gray100 : gray200
                    },

                    "&:active": {
                        background: toMd ? gray200 : gray300
                    }
                })}
            >
                {t("Notifications##notifications")}
            </Button>

            {toMd ? (
                <NotificationsMobile
                    isOpen={isDialogOpen}
                    close={closeDialog}
                />
            ) : (
                <NotificationsDesktop anchorEl={anchorEl} close={closeMenu} />
            )}
        </>
    );
};

export default Notifications;
