import { ENDPOINTS, FILTER_NAMES, FilterId } from "../../types";

export const getStatisticsEndpoint = (
    rootCompanyId: string | string[] | undefined,
    doesHaveSameRootId: boolean | undefined,
    id: FilterId[] | number | undefined,
    doesIncludeHierarchy: boolean | undefined
) => {
    const rootCompanyParams = `${FILTER_NAMES.RootCompany}=${doesHaveSameRootId ? id : rootCompanyId}`;

    const isCompanyIdExists = Array.isArray(id)
        ? id.length !== 0
        : id !== undefined;

    const companyIdParams =
        isCompanyIdExists && !doesIncludeHierarchy
            ? `&${FILTER_NAMES.CompanyId}=${id}`
            : "";

    const params = `?${rootCompanyParams}${companyIdParams}`;

    return `${ENDPOINTS.Companies}/stats${params}`;
};

export const getReloadStatus = (
    reloadDetailsData: boolean,
    isReccuringLoading: boolean | undefined
) => (isReccuringLoading !== undefined ? reloadDetailsData : false);
