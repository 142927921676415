/** @jsxImportSource @emotion/react */
import { css } from "@emotion/react";

import { useTranslation } from "react-i18next";

import InternalLog from "./InternalLog";
import CanAdapters from "./CanAdapters";
import CanVehicles from "./CanVehicles";
import FeatureControl from "./FeatureControl";
import GeneralDropdown from "./GeneralDropdown";
import { CUSTOM_SECOND_SELECT, SecondSelectContentProps } from "./types";

import { renderAdditionalBanner } from "../functions";

const SecondSelectContent = ({
    secondSelectName,
    type,
    isLoading,
    internalLogProps,
    canAdapterProps,
    canVehiclesProps,
    featureControlProps,
    generalDropdownProps,
    changePayload
}: SecondSelectContentProps) => {
    const { t } = useTranslation();

    const renderContent = () => {
        switch (secondSelectName) {
            case CUSTOM_SECOND_SELECT.Logs: {
                const { additionalData, changeOrder, changeInputValue } =
                    internalLogProps;

                return (
                    <InternalLog
                        isLoading={isLoading}
                        additionalData={additionalData}
                        changePayload={changePayload}
                        changeOrder={changeOrder}
                        changeInputValue={changeInputValue}
                    />
                );
            }
            case CUSTOM_SECOND_SELECT.CanAdapters: {
                const {
                    additionalData,
                    changeDateValue,
                    changeRevisionValue,
                    changeAdapterValue
                } = canAdapterProps;

                return (
                    <CanAdapters
                        isLoading={isLoading}
                        type={type}
                        additionalData={additionalData}
                        changePayload={changePayload}
                        changeDateValue={changeDateValue}
                        changeRevisionValue={changeRevisionValue}
                        changeAdapterValue={changeAdapterValue}
                    />
                );
            }
            case CUSTOM_SECOND_SELECT.CanVehicles: {
                const { additionalData, changeMake, changeModel } =
                    canVehiclesProps;

                return (
                    <CanVehicles
                        isLoading={isLoading}
                        additionalData={additionalData}
                        changePayload={changePayload}
                        changeMake={changeMake}
                        changeModel={changeModel}
                    />
                );
            }
            case CUSTOM_SECOND_SELECT.FeatureControl: {
                const { features, feature, changeFeature } =
                    featureControlProps;

                return (
                    <FeatureControl
                        isLoading={isLoading}
                        features={features}
                        feature={feature}
                        changePayload={changePayload}
                        changeFeature={changeFeature}
                    />
                );
            }
            default: {
                const { additionalData, changeAdditionalData } =
                    generalDropdownProps;

                return (
                    <GeneralDropdown
                        secondSelectName={secondSelectName}
                        isLoading={isLoading}
                        taskType={type}
                        additionalData={additionalData}
                        additionalBanner={renderAdditionalBanner(
                            secondSelectName,
                            additionalData,
                            t
                        )}
                        changePayload={changePayload}
                        changeAdditionalData={changeAdditionalData}
                    />
                );
            }
        }
    };

    return (
        <div
            css={css({
                marginTop: "16px"
            })}
        >
            {renderContent()}
        </div>
    );
};

export default SecondSelectContent;
