import { useContext } from "react";

import CompanyHeader from "./CompanyHeader";
import CompanyLinks from "./CompanyLinks";

import { IndividualDetailsProps } from "../types";

import CompanyHierarchy from "../../CompanyHierarchy/CompanyHierarchy";

import ThemeContext from "../../../context/theme/themeContext";
import { useRecurringLoading } from "../../../shared";

const CompanyDetails = ({ isLoading }: IndividualDetailsProps) => {
    const {
        colorsFacelift: { textDark }
    } = useContext(ThemeContext);

    const { isReccuringLoading } = useRecurringLoading();

    const titleCSS = {
        color: textDark,
        fontWeight: 700,
        fontSize: "14px",
        lineHeight: "20px",
        letterSpacing: "0.1px"
    };

    return (
        <>
            <CompanyHeader isLoading={isLoading} />

            <CompanyLinks
                isReccuringLoading={isReccuringLoading}
                titleCSS={titleCSS}
            />

            <CompanyHierarchy
                isReccuringLoading={isReccuringLoading}
                titleCSS={titleCSS}
            />
        </>
    );
};

export default CompanyDetails;
