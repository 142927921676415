import {
    SET_RESOURCE,
    RESET_FILTER_PARAMS,
    RESET_ALL_PARAMS,
    SET_QUERY_PARAMS,
    SET_FILTERED_VALUES,
    SET_COMPANY_FILTERED_VALUES,
    UPDATE_FILTER_PARAMS,
    RELOAD_ITEMS,
    TOGGLE_TABLE_ROW_DESELECTION,
    CHANGE_ROOT_COMPANY,
    CHANGE_ROOT_COMPANY_LEVEL,
    TRIGGER_ROOT_COMPANY,
    CHANGE_CUSTOM_RESOURCE_LIMITS
} from "./paramsActions";

import {
    distributedQueryParams,
    getActiveQueryParams,
    getCompanyFilterValue,
    getCompanyFilteredValues,
    getCompanyLevel,
    getCompanyType,
    getFilterDropdownOptions,
    getFilteredValues,
    getResource,
    getRootClientCompanyId,
    getRootCompany,
    getRootCompanyAndCompanyId,
    getRootCustomResourceLimitSettings,
    getRowsPerPageValue,
    getUpdatedReloadItems,
    onlyCompaniesOrAllExceptCompanies,
    removeFilterAndSearchParams,
    removeFromBrowser,
    removeNonExistentQueryParams,
    removeNonValidQueryParams,
    removeParams,
    saveToBrowser
} from "./functions";

import { Action, ParamsInitialState, REMOVE_PARAMS_CONDITIONS } from "./types";

import {
    getFilteredQueryParams,
    useUrlQueryParams,
    useWindowLocation,
    QueryParams,
    QueryParamName,
    FILTER_NAMES,
    getStringifiedParams,
    NOT_FILTER_NAMES,
    TABLE_NAMES
} from "../../shared";

const ParamsReducer = (
    state: ParamsInitialState,
    action: Action
): ParamsInitialState => {
    const { sendParamsToUrl } = useUrlQueryParams();
    const { resource } = useWindowLocation();

    switch (action.type) {
        case SET_RESOURCE: {
            return {
                ...state,
                resource: action.payload
            };
        }
        case SET_QUERY_PARAMS: {
            const {
                rootCompany,
                rootCompanyLevel,
                rootCompanyType,
                rootClientCompanyId,
                rootCustomResourceLimitSettings,
                resetParams,
                removeFilterOptions,
                updatedParams,
                filteredValues,
                companyFilteredValues
            } = action.payload;

            const isEmpty = Object.keys(updatedParams).length === 0;

            const companyFilterValue = getCompanyFilterValue(
                FILTER_NAMES.CompanyId,
                updatedParams[FILTER_NAMES.CompanyId]
            );

            const rowsPerPageValue = getRowsPerPageValue(
                updatedParams.per_page,
                resource
            );

            const activeQueryParams = getActiveQueryParams(
                isEmpty,
                companyFilterValue,
                rowsPerPageValue,
                state,
                resetParams,
                updatedParams
            );

            const queryParamsArray = Object.entries(activeQueryParams) as [
                QueryParamName,
                string
            ][];

            removeNonValidQueryParams(queryParamsArray, activeQueryParams);

            const stringifiedParams = getStringifiedParams(activeQueryParams);

            sendParamsToUrl({
                ...updatedParams,
                [NOT_FILTER_NAMES.PerPage]: rowsPerPageValue
            });

            const { rootCompanyParams, companyParams, allOtherParams } =
                distributedQueryParams(activeQueryParams);

            saveToBrowser(
                resource,
                allOtherParams,
                rootCompanyParams,
                companyParams
            );

            const updatedFilteredValues = getFilteredValues(
                [...state.filteredValues],
                filteredValues
            );

            const updatedCompanyFilteredValues = getCompanyFilteredValues(
                [...state.companyFilteredValues],
                companyFilteredValues
            );

            return {
                ...state,
                resource: getResource(isEmpty, state.resource),
                queryParams: activeQueryParams,
                filterDropdownOptions: removeFilterOptions
                    ? {}
                    : state.filterDropdownOptions,
                filterQueryParams: getFilteredQueryParams(activeQueryParams),
                filteredValues: updatedFilteredValues,
                companyFilteredValues: updatedCompanyFilteredValues,
                stringParams: stringifiedParams,
                rootCompany: rootCompany || state.rootCompany,
                rootCompanyLevel: getCompanyLevel(
                    rootCompanyLevel,
                    state.rootCompanyLevel
                ),
                rootCompanyType: getCompanyType(
                    rootCompanyType,
                    state.rootCompanyType
                ),
                rootClientCompanyId: getRootClientCompanyId(
                    rootClientCompanyId,
                    state.rootClientCompanyId
                ),
                rootCustomResourceLimitSettings:
                    getRootCustomResourceLimitSettings(
                        rootCustomResourceLimitSettings,
                        state.rootCustomResourceLimitSettings
                    )
            };
        }
        case CHANGE_ROOT_COMPANY: {
            const {
                params,
                rootCompanyLevel,
                rootCompanyType,
                rootClientCompanyId,
                rootCustomResourceLimitSettings
            } = action.payload;

            const currentParams = { ...state.queryParams };

            const rootCompanyId = params[FILTER_NAMES.RootCompany] as string;
            const paramNames = Object.keys(currentParams) as QueryParamName[];

            const removableParams = removeParams(
                paramNames,
                currentParams,
                REMOVE_PARAMS_CONDITIONS.AllForRootCompanyChange
            );

            const updatedParams = { ...currentParams, ...params };

            const stringifiedParams = getStringifiedParams(updatedParams);

            sendParamsToUrl({ ...removableParams, ...updatedParams });

            const { rootCompanyParams, companyParams, allOtherParams } =
                distributedQueryParams(updatedParams);

            saveToBrowser(
                resource,
                allOtherParams,
                rootCompanyParams,
                companyParams
            );

            const updatedRootCompany = getRootCompany(
                state.filterDropdownOptions,
                state.rootCompany,
                rootCompanyId
            );

            return {
                ...state,
                queryParams: updatedParams,
                filterQueryParams: {
                    [FILTER_NAMES.RootCompany]: rootCompanyId
                },
                filteredValues: [],
                companyFilteredValues: [],
                stringParams: stringifiedParams,
                rootCompany: updatedRootCompany,
                rootCompanyLevel: getCompanyLevel(
                    rootCompanyLevel,
                    state.rootCompanyLevel
                ),
                rootCompanyType: getCompanyType(
                    rootCompanyType,
                    state.rootCompanyType
                ),
                rootClientCompanyId: getRootClientCompanyId(
                    rootClientCompanyId,
                    state.rootClientCompanyId
                ),
                rootCustomResourceLimitSettings
            };
        }
        case CHANGE_ROOT_COMPANY_LEVEL: {
            return {
                ...state,
                rootCompanyLevel: action.payload
            };
        }
        case CHANGE_CUSTOM_RESOURCE_LIMITS: {
            return {
                ...state,
                rootCustomResourceLimitSettings: action.payload
            };
        }
        case TRIGGER_ROOT_COMPANY: {
            const hasTableReload = action.payload;

            const currentCompaniesReload =
                state.reloadItems[TABLE_NAMES.Companies];

            return {
                ...state,
                filterDropdownOptions: {
                    ...state.filterDropdownOptions,
                    [FILTER_NAMES.RootCompany]: undefined
                },
                reloadRootCompany: state.reloadRootCompany + 1,
                reloadItems: {
                    ...state.reloadItems,
                    [TABLE_NAMES.Companies]: hasTableReload
                        ? currentCompaniesReload + 1
                        : currentCompaniesReload
                }
            };
        }
        case SET_FILTERED_VALUES: {
            const updatedQueryParams = { ...state.queryParams };
            const { data, filterDropdownOptions } = action.payload;

            const removableParams = removeNonExistentQueryParams(
                data,
                updatedQueryParams
            );

            const stringifiedParams = getStringifiedParams(updatedQueryParams);

            sendParamsToUrl(removableParams);

            const { rootCompanyParams, companyParams, allOtherParams } =
                distributedQueryParams(updatedQueryParams);

            saveToBrowser(
                resource,
                allOtherParams,
                rootCompanyParams,
                companyParams
            );

            return {
                ...state,
                queryParams: updatedQueryParams,
                filterDropdownOptions: getFilterDropdownOptions(
                    state.filterDropdownOptions,
                    filterDropdownOptions
                ),
                filteredValues: data.filter(
                    singleFilterData => singleFilterData.value.length
                ),
                stringParams: stringifiedParams
            };
        }
        case SET_COMPANY_FILTERED_VALUES: {
            const updatedQueryParams = { ...state.queryParams };
            const { filteredValues, filterDropdownOptions } = action.payload;

            const data = [
                { filterName: FILTER_NAMES.CompanyId, value: filteredValues }
            ];

            const removableParams = removeNonExistentQueryParams(
                data,
                updatedQueryParams
            );

            const stringifiedParams = getStringifiedParams(updatedQueryParams);

            sendParamsToUrl(removableParams);

            const { rootCompanyParams, companyParams, allOtherParams } =
                distributedQueryParams(updatedQueryParams);

            saveToBrowser(
                resource,
                allOtherParams,
                rootCompanyParams,
                companyParams
            );

            return {
                ...state,
                queryParams: updatedQueryParams,
                filterDropdownOptions,
                companyFilteredValues: filteredValues,
                stringParams: stringifiedParams
            };
        }
        case UPDATE_FILTER_PARAMS: {
            const { updatedParams, filteredValues, companyFilteredValues } =
                action.payload;

            const currentQueryParams = { ...state.queryParams };
            const removableParams: QueryParams = {};

            const paramNames = Object.keys(
                currentQueryParams
            ) as QueryParamName[];

            paramNames.forEach(key =>
                removeFilterAndSearchParams(
                    key,
                    removableParams,
                    currentQueryParams
                )
            );

            const updatedQueryParams = {
                ...currentQueryParams,
                ...updatedParams
            };

            const stringifiedParams = getStringifiedParams(updatedQueryParams);

            sendParamsToUrl({ ...removableParams, ...updatedParams });

            const { rootCompanyParams, companyParams, allOtherParams } =
                distributedQueryParams(updatedQueryParams);

            saveToBrowser(
                resource,
                allOtherParams,
                rootCompanyParams,
                companyParams
            );

            return {
                ...state,
                queryParams: updatedQueryParams,
                filterQueryParams: getFilteredQueryParams(updatedQueryParams),
                filteredValues: filteredValues ? [filteredValues] : [],
                companyFilteredValues: companyFilteredValues || [],
                stringParams: stringifiedParams
            };
        }
        case RESET_FILTER_PARAMS: {
            const onlyCompanies = action.payload;
            const updatedQueryParams = { ...state.queryParams };

            const paramNames = Object.keys(
                updatedQueryParams
            ) as QueryParamName[];

            const removableParams = removeParams(
                paramNames,
                updatedQueryParams,
                onlyCompaniesOrAllExceptCompanies(onlyCompanies)
            );

            const stringifiedParams = getStringifiedParams(updatedQueryParams);

            sendParamsToUrl(removableParams);

            const { allOtherParams } =
                distributedQueryParams(updatedQueryParams);

            removeFromBrowser(
                onlyCompaniesOrAllExceptCompanies(onlyCompanies),
                resource,
                allOtherParams
            );

            return {
                ...state,
                queryParams: updatedQueryParams,
                filterQueryParams: onlyCompanies
                    ? getFilteredQueryParams(updatedQueryParams)
                    : getRootCompanyAndCompanyId(updatedQueryParams),
                filteredValues: onlyCompanies ? state.filteredValues : [],
                companyFilteredValues: onlyCompanies
                    ? []
                    : state.companyFilteredValues,
                stringParams: stringifiedParams
            };
        }
        case RESET_ALL_PARAMS: {
            const updatedQueryParams = { ...state.queryParams };

            const paramNames = Object.keys(
                updatedQueryParams
            ) as QueryParamName[];

            const removableParams = removeParams(
                paramNames,
                updatedQueryParams,
                REMOVE_PARAMS_CONDITIONS.AllFiltersAndQueryExceptCompanies
            );

            const stringifiedParams = getStringifiedParams(updatedQueryParams);

            sendParamsToUrl(removableParams);

            removeFromBrowser(
                REMOVE_PARAMS_CONDITIONS.AllFiltersAndQueryExceptCompanies,
                resource,
                updatedQueryParams
            );

            return {
                ...state,
                queryParams: updatedQueryParams,
                filterQueryParams:
                    getRootCompanyAndCompanyId(updatedQueryParams),
                filteredValues: [],
                stringParams: stringifiedParams
            };
        }
        case TOGGLE_TABLE_ROW_DESELECTION: {
            return {
                ...state,
                tableRowDeselectionToggle: !state.tableRowDeselectionToggle
            };
        }
        case RELOAD_ITEMS: {
            return {
                ...state,
                reloadItems: getUpdatedReloadItems(
                    state.reloadItems,
                    action.payload
                )
            };
        }
        default:
            return state;
    }
};

export default ParamsReducer;
